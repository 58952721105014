export const Search = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7008 20.0204L18.4165 15.7368C18.2231 15.5435 17.961 15.4361 17.686 15.4361H16.9855C18.1716 13.9194 18.8763 12.0118 18.8763 9.93663C18.8763 5 14.8756 1 9.93815 1C5.00068 1 1 5 1 9.93663C1 14.8733 5.00068 18.8733 9.93815 18.8733C12.0137 18.8733 13.9216 18.1686 15.4386 16.9828V17.6831C15.4386 17.9581 15.546 18.2202 15.7394 18.4135L20.0237 22.6971C20.4276 23.101 21.0808 23.101 21.4804 22.6971L22.6965 21.4812C23.1004 21.0773 23.1004 20.4243 22.7008 20.0204ZM9.93815 15.4361C6.90004 15.4361 4.43775 12.9785 4.43775 9.93663C4.43775 6.89903 6.89574 4.43716 9.93815 4.43716C12.9763 4.43716 15.4386 6.89474 15.4386 9.93663C15.4386 12.9742 12.9806 15.4361 9.93815 15.4361Z"
      fill="#9C9DA1"
    />
  </svg>
)
