import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import styles from "./Search.module.scss"
import { Search as SearchIcon } from "../../css/icons/Search"
import { formatLocationName } from "../../helpers/location"
import { City, Microphone } from "../../css/icons"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"

const Search = ({ isMobile }) => {
  const history = useHistory()
  const [query, setQuery] = useState("")
  const autocompleteRef = useRef()

  useDetectClickOutside(autocompleteRef, () => {
    setResults([])
  })

  const [results, setResults] = useState([])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getAutocompleteSuggestions(query)
      // Send Axios request here
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const getAutocompleteSuggestions = async (query) => {
    if (query === "") {
      setResults([])
      return
    }

    try {
      const endpoint = "/api/stagehand/dropdown"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })

      let count = 0
      let searchResults = []

      response.performers &&
        response.performers.forEach((performer) => {
          if (count < 10) {
            searchResults.push({ ...performer, type: "artist" })
            count++
          }
        })

      response.venues &&
        response.venues.forEach((venue) => {
          if (count < 10) {
            searchResults.push({ ...venue, type: "venue" })
            count++
          }
        })

      response.cbsas &&
        response.cbsas.forEach((cbsa) => {
          if (count < 10) {
            // remove duplicate cbsas
            if (
              !searchResults.find(
                (existingCbsa) => cbsa.cbsaname === existingCbsa.cbsaname
              )
            ) {
              searchResults.push({ ...cbsa, type: "cbsa" })
              count++
            }
          }
        })

      setResults(searchResults)

      // return response.events
    } catch (err) {
      console.log(err)
    }
  }

  const getUniqueKey = (result) => {
    if (result.type === "artist") {
      return result.performer_id
    } else if (result.type === "venue") {
      return result.venue_id
    } else if (result.type === "cbsa") {
      return result.cbsaname
    }
  }

  return (
    <div
      className={classNames(
        styles.searchContainer,
        styles.box,
        isMobile && styles.isMobile
      )}
    >
      <SearchIcon />
      <input
        className={styles.search}
        type="text"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search by artist or city"
        autoComplete="new-password" // weird hack from stack overflow but works
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            history.push(`/events/search/${query}`)
            setResults([]) // close the suggestion dropdown
          }
        }}
      />

      {results.length > 0 && (
        <div className={styles.autocomplete} ref={autocompleteRef}>
          {results?.map((result) => (
            <div
              className={styles.autocompleteItem}
              key={getUniqueKey(result)}
              onClick={() => {
                if (result.type === "artist") {
                  history.push(`/artist/${result.performer_id}`)
                  setResults([])
                } else if (result.type === "venue") {
                  history.push(
                    `/events?venue_id=${result.venue_id}&venue_name=${result.name}`
                  )
                } else if (result.type === "cbsa") {
                  history.push(`/events?location=${result.cbsaname}`)
                }
              }}
            >
              <div className={styles.autocompleteIconContainer}>
                {result.type === "artist" && <Microphone />}
                {result.type === "venue" && <City />}
                {result.type === "cbsa" && <City />}
              </div>
              <div>
                <span className={styles.autocompleteItemLabel}>
                  {result.type === "artist" && "Artist"}
                  {result.type === "venue" && "Venue"}
                  {result.type === "cbsa" && "City"}
                </span>
                <p className={styles.autocompleteItemTitle}>
                  {result.type === "artist" && result.name}
                  {result.type === "venue" && result.name}
                  {result.type === "cbsa" &&
                    formatLocationName(result.cbsaname)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Search
