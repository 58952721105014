import { useEffect, useState } from "react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import classNames from "classnames"
import { CardCarousel, Footer, Header } from "../components"
import EarlyAccessModal from "./EarlyAccessModal"
import AccessRequestedModal from "./AccessRequestedModal"
import styles from "./VelvetRope.module.scss"
import { AngleDown } from "../css/icons"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../reducers/userSlice"
import postToSlack from "../postToSlack"
import {
  getRecentlyAddedEvents,
  getEventsByLocation,
} from "../reducers/eventSlice"

const VelvetRope = () => {
  const [showEarlyAccessModal, setShowEarlyAccessModal] = useState(false)
  const [ticketCount, setTicketCount] = useState(" ")
  const [showAccessRequestedModal, setShowAccessRequestedModal] =
    useState(false)
  const [locationCount, setLocationCount] = useState(0)

  const recentlyAddedEvents = useSelector(
    (state) => state.events.recentlyAddedEvents
  )

  const user = useSelector((state) => state.user.user)
  const eventsByLocation = useSelector((state) => state.events.eventsByLocation)

  const dispatch = useDispatch()

  const locationList = ["Chicago", "New York", "Miami"]

  useEffect(() => {
    dispatch(getRecentlyAddedEvents({ just_added: true }))

    locationList.forEach((location) => {
      dispatch(getEventsByLocation({ cbsa: location }))
    })

    getTicketCount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // upon user login hit the dispenser
  useEffect(() => {
    if (user?.publicKey) {
      if (!window.authenticating) {
        auntheticateUser()
      }
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const getTicketCount = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/ticket-count`
    let headers = {
      "Content-Type": "application/json",
    }
    try {
      let resp = await fetch(url, {
        method: "get",
        headers: headers,
      })
      resp = await resp.json()
      setTicketCount(resp.count)
      setLocationCount(resp.cities)
    } catch (err) {
      console.log("error getting ticket count", err)
    }
  }

  /*
    Auntheticate:
    -- whitelist
    -- has XP cash
    -- has one from the collection



  */

  const authneticatePhone = async () => {
    let data = {
      phone: user.phone,
    }
    let headers = {
      "Content-Type": "application/json",
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropephone`
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      let u = user
      let u2 = JSON.parse(JSON.stringify(u))
      let msg
      if (resp.valid) {
        u2.authenticated = true
        msg = `${u.publicKey.toString()} (${
          u.loginMethod
        }) got through velvet rope`
      } else {
        msg = `${u.publicKey.toString()} (${
          u.loginMethod
        }) got denied velvet rope`
        u2.authenticated = false
        setShowEarlyAccessModal(true)
      }
      postToSlack(msg, "xp")
      dispatch(setUser(u2))
    } catch (err) {
      // TODO add sentry alert here
      window.authenticating = false
      console.log("restrictor error", err)
    }
  }

  const auntheticateUser = async () => {
    window.authenticating = true

    if (user.loginMethod === "phone") {
      return await authneticatePhone()
    }

    let headers = {
      "Content-Type": "application/json",
    }
    let urls = [
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetrope`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropexpcash`,
      `${process.env.REACT_APP_EXPRESS_API}/api/velvet/velvetropexpchip`,
    ]

    let authed = false
    let u = user
    let u2 = JSON.parse(JSON.stringify(u))
    for (let url of urls) {
      let data = {
        account: user.publicKey.toString(),
      }
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      if (resp.valid) {
        u2.authenticated = true
        let msg = `${u.publicKey.toString()} (${
          u.loginMethod
        }) got through velvet rope = ${url}`
        postToSlack(msg, "xp")
        dispatch(setUser(u2))
        return
      }
    }

    let msg = `${u.publicKey.toString()} (${
      u.loginMethod
    }) got denied velvet rope`
    u2.authenticated = false
    setShowEarlyAccessModal(true)
    postToSlack(msg, "xp")
    dispatch(setUser(u2))
  }

  const ChicagoTile = () => (
    <div
      className={classNames(styles.cityTile, "in-carousel is-start")}
      onClick={() => setShowEarlyAccessModal(true)}
    >
      <img
        alt="Chicago"
        className={styles.cityImage}
        src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/chicago_noglow+1.png"
      />
      <div className={styles.cityContent}>
        <div className={styles.cityDescription}>See all</div>
        <div className={styles.cityDescription}>
          {eventsByLocation["Chicago"]?.length} events in
        </div>
        <div className={styles.cityName}>
          Chicago{" "}
          <img
            alt="arrow"
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/arrow_noglow+1.png"
          />
        </div>
      </div>
    </div>
  )

  const NewYorkTile = () => (
    <div
      className={classNames(styles.cityTile, "in-carousel is-start")}
      onClick={() => setShowEarlyAccessModal(true)}
    >
      <img
        alt="New York"
        className={styles.cityImage}
        src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/new_york_noglow+1.png"
      />
      <div className={styles.cityContent}>
        <div className={styles.cityDescription}>See all</div>
        <div className={styles.cityDescription}>
          {eventsByLocation["New York"]?.length} events in
        </div>
        <div className={styles.cityName}>
          New York{" "}
          <img
            alt="arrow"
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/arrow_noglow+1.png"
          />
        </div>
      </div>
    </div>
  )

  const MiamiTile = () => (
    <div
      className={classNames(styles.cityTile, "in-carousel is-start")}
      onClick={() => setShowEarlyAccessModal(true)}
    >
      <img
        alt="Miami"
        className={styles.cityImage}
        src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/miami_noglow+1.png"
      />
      <div className={styles.cityContent}>
        <div className={styles.cityDescription}>See all</div>
        <div className={styles.cityDescription}>
          {eventsByLocation["Miami"]?.length} events in
        </div>
        <div className={styles.cityName}>
          Miami{" "}
          <img
            alt="arrow"
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/arrow_noglow+1.png"
          />
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.landingContainer}>
      <div className={styles.bodyContent}>
        <Header
          velvetHeader
          velvetOnClick={() => setShowEarlyAccessModal(true)}
        />
        <div className={styles.hero}>
          <p className={styles.heroEyebrow}>
            Find verified tickets - minus junk fees
          </p>
          <h1>Welcome to</h1>
          <img
            className={styles.bigLogo}
            alt="XP Logo"
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
          />
          <p className={styles.heroSubtitle}>
            XP is an event marketplace created for fans - by fans.
          </p>
          <p className={styles.heroSubtitle}>
            With thousands of tickets already available, we’re opening early
            access to select fans
          </p>

          <div className={styles.tensor}>
            <div className={styles.tensorLeft}>
              <div className={styles.tensorLeftTitle}>
                GOT aN xp chip from tensor?
              </div>
              <div className={styles.tensorLeftText}>
                Connect your wallet to unlock early access to XP and get an XP
                Cash reward. You can use XP Cash to get tickets to your favorite
                live events!
              </div>
              <WalletMultiButton> Connect Wallet </WalletMultiButton>
            </div>

            <img
              className={styles.tensorTiles}
              src="https://hngr-icons.s3.amazonaws.com/both_xp_tiles_1+1.png"
              alt="tiles"
            />
          </div>

          <div
            className={styles.outlineButton}
            onClick={() => setShowEarlyAccessModal(true)}
          >
            <img
              className={styles.logo}
              alt="XP Logo"
              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
            />
            Request Early Access
          </div>

          <AngleDown className={styles.down} />
        </div>
        <div className={styles.carouselSection}>
          <h2>Recently Listed</h2>
          <p className={styles.sectionSubtitle}>
            <span onClick={() => setShowEarlyAccessModal(true)}>
              Request early access
            </span>{" "}
            to view all available tickets
          </p>

          <CardCarousel
            eventList={recentlyAddedEvents.slice(0, 6)}
            carouselId={1}
            className={styles.carousel1}
            customTile={window.innerWidth > 460 && <ChicagoTile />}
            carouselOnClick={() => setShowEarlyAccessModal(true)}
          />
          {window.innerWidth < 460 && <ChicagoTile />}
          <CardCarousel
            eventList={recentlyAddedEvents.slice(7, 12)}
            carouselId={2}
            className={styles.carousel2}
            customTile={window.innerWidth > 460 && <NewYorkTile />}
            carouselOnClick={() => setShowEarlyAccessModal(true)}
          />
          {window.innerWidth < 460 && <NewYorkTile />}

          <CardCarousel
            eventList={recentlyAddedEvents.slice(13, 18)}
            carouselId={3}
            className={styles.carousel3}
            customTile={window.innerWidth > 460 && <MiamiTile />}
            carouselOnClick={() => setShowEarlyAccessModal(true)}
          />
          {window.innerWidth < 460 && <MiamiTile />}
        </div>
        <div className={styles.section}>
          <h1>
            {ticketCount} Tickets across {locationCount} Cities
          </h1>
          <p className={styles.sectionSubtitle}>
            Join a community of exclusive buyers and sellers
          </p>
        </div>

        <div className={styles.sectionContainer}>
          <img
            className={styles.gallery}
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Music+Gallery.png"
            alt="gallery"
          />
          <div className={styles.sectionContent}>
            <div className={styles.sectionColumn}>
              <h3>The Exchange</h3>
              <p>
                Buy and sell tickets 100% securely within the XP community.
                Tickets are 100% verified by XP on the public ledger.
                Transactions happen instantly on-chain.
              </p>
            </div>
            <div
              className={classNames(styles.sectionColumn, styles.sectionRight)}
            >
              <h3>The Concierge</h3>
              <p>
                Even if what you’re looking for isn’t on the exchange, XP can
                help. Leverage our network of ticket brokers to find the exact
                tickets you need.
              </p>
            </div>
          </div>
          <div
            className={styles.outlineButton}
            onClick={() => setShowEarlyAccessModal(true)}
          >
            <img
              className={styles.logo}
              alt="XP Logo"
              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
            />
            Request Early Access
          </div>
        </div>
      </div>
      <Footer
        velvetFooter
        velvetOnClick={() => setShowEarlyAccessModal(true)}
      />

      {showEarlyAccessModal && (
        <EarlyAccessModal
          close={() => setShowEarlyAccessModal(false)}
          closeOnSubmit={() => {
            setShowEarlyAccessModal(false)
            setShowAccessRequestedModal(true)
          }}
        />
      )}
      {showAccessRequestedModal && (
        <AccessRequestedModal
          close={() => setShowAccessRequestedModal(false)}
        />
      )}
    </div>
  )
}

export default VelvetRope
