import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
} from "@captainxyz/solana-core"

import { PublicKey } from "@solana/web3.js"

export const getTokenAccountsForUser = async (adapter, user) => {
  setConfig("mainnet-beta", {
    rpcEndpoint:process.env.REACT_APP_RPC
  })

  let operator, tokens
  if (adapter?.publicKey) {
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    operator = new Operator("mainnet-beta", walletAdapterIdentity)
    tokens = await TokenAccount.find(operator)
  } else if (user?.publicKey){
    operator = new Operator("mainnet-beta")
    tokens = await TokenAccount.find(operator, new PublicKey(user.publicKey))
  } else {
    return { decryptList:[], decryptListPast:[] }
  }
  let decryptList = []
  let decryptListPast = []
  for (let token of tokens) {
    if (token.metadata?.json?.symbol === "DEVXP") {
      if (
        new Date(
          token.metadata?.json?.attributes?.find(
            (attr) => attr.trait_type === "Date"
          ).value
        ) > new Date()
      ) {
        decryptList.push(token)
      } else {
        decryptListPast.push(token)
      }
    }
  }

  return { decryptList, decryptListPast }
}
