import { useEffect, useState } from "react"
import classNames from "classnames"
import { Button, Input, Modal } from "../components"
import { AngleDown, CloseCircle, Circle, FilledCircle } from "../css/icons"
import styles from "./EarlyAccessModal.module.scss"

const EarlyAccessModal = ({ close, closeOnSubmit }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [telephone, setTelephone] = useState("")
  const [walletAddress, setWalletAddress] = useState("")
  const [discovery, setDiscovery] = useState("Select a method")
  const [showDiscoveryOptions, setShowDiscoveryOptions] = useState(false)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  const handleFormSubmit = async () => {
    const response = await fetch("https://formspree.io/f/xrgwgaye", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        telephone,
        walletAddress,
        discovery,
      }),
    }).then(() => {
      closeOnSubmit()
    })

    return response
  }

  return (
    <Modal onClose={() => close()} className={styles.accessModal}>
      <div className={styles.container}>
        <CloseCircle className={styles.close} onClick={() => close()} />
        <div className={styles.icon}>⏰</div>
        <div className={styles.heading}>Request Early Access</div>
        <div className={styles.subtext}>
          Be the first to our private beta by providing your information below
          and stay updated on exclusive releases
        </div>
        <div className={styles.inputContainer}>
          <label for="name">Name</label>
          <Input
            type="name"
            name="name"
            id="name"
            placeholder="Bruce Springsteen"
            required=""
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <label for="email-address">Email Address</label>
          <Input
            type="email"
            name="_replyto"
            id="email-address"
            placeholder="email@domain.tld"
            required=""
            onChange={(e) => {
              setEmail(e.target.value)
              validateEmail(e.target.value)
            }}
          />
        </div>
        <div className={styles.inputContainer}>
          <label for="telephone">Telephone Number</label>
          <Input
            type="telephone"
            name="telephone"
            id="telephone"
            placeholder="(555) 555-5555"
            onChange={(e) => setTelephone(e.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <label for="telephone">Wallet Address</label>
          <Input
            type="name"
            name="wallet_address"
            id="wallet_address"
            placeholder="sklSOd23jAOEdoc83s91039cjf29KDClsox93"
            onChange={(e) => setWalletAddress(e.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <label for="timely">How did you hear about us?</label>
          <div className={styles.optionsContainer}>
            <div
              className={styles.select}
              onClick={() => setShowDiscoveryOptions(!showDiscoveryOptions)}
            >
              <span>{discovery}</span>{" "}
              <AngleDown className={styles.iconRight} />
            </div>
            {showDiscoveryOptions && (
              <div className={styles.selectOptions}>
                <div
                  className={classNames(styles.selectItem)}
                  key={"Tensor"}
                  onClick={() => {
                    setDiscovery("Tensor")
                    setShowDiscoveryOptions(false)
                  }}
                >
                  <span>Tensor</span>
                  {discovery === "Tensor" ? <FilledCircle /> : <Circle />}
                </div>
                <div
                  className={classNames(styles.selectItem)}
                  key={"Nashville conference"}
                  onClick={() => {
                    setDiscovery("Nashville conference")
                    setShowDiscoveryOptions(false)
                  }}
                >
                  <span>Nashville Conference</span>
                  {discovery === "Nashville conference" ? (
                    <FilledCircle />
                  ) : (
                    <Circle />
                  )}
                </div>
                <div
                  className={classNames(styles.selectItem)}
                  key={"Social media"}
                  onClick={() => {
                    setDiscovery("Social media")
                    setShowDiscoveryOptions(false)
                  }}
                >
                  <span>Social media</span>
                  {discovery === "Social media" ? <FilledCircle /> : <Circle />}
                </div>
                <div
                  className={classNames(styles.selectItem)}
                  key={"From a colleague or friend"}
                  onClick={() => {
                    setDiscovery("From a colleague or friend")
                    setShowDiscoveryOptions(false)
                  }}
                >
                  <span>From a colleague or friend</span>
                  {discovery === "From a colleague or friend" ? (
                    <FilledCircle />
                  ) : (
                    <Circle />
                  )}
                </div>
                <div
                  className={classNames(styles.selectItem)}
                  key={"Other"}
                  onClick={() => {
                    setDiscovery("Other")
                    setShowDiscoveryOptions(false)
                  }}
                >
                  <span>Other</span>
                  {discovery === "Other" ? <FilledCircle /> : <Circle />}
                </div>
              </div>
            )}
          </div>
          <Button
            fullWidth
            className={styles.button}
            variant="blue"
            onClick={() => handleFormSubmit()}
            disabled={!validEmail}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default EarlyAccessModal
