import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null,
  preferredLocation: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        // anything else we need to add about the user here
      }
    },
    signOut: (state) => {
      window.authenticating = false
      localStorage.removeItem("publicKey")
      state.user = null
    },
    setPreferredLocation: (state, action) => {
      state.preferredLocation = action.payload
    },
  },
})

export const { setPreferredLocation, setUser, signOut } = userSlice.actions

export default userSlice.reducer
