import { useHistory } from "react-router-dom"
import { useState, useEffect } from "react"
import { Button, Modal } from "../"
import styles from "./SwapModal.module.scss"
import "./SwapModal.css"
import { useWallet } from "@solana/wallet-adapter-react"
import { Base64 } from "js-base64"
import { PublicKey, Connection, Transaction } from "@solana/web3.js" // eslint-disable-line no-unused-vars
import classNames from "classnames"
import {
  setConfig,
  PDA,
  Operator,
  WalletAdapterIdentity,
  KeypairIdentity,
  Currency,
  TokenAccount,
  MerkelTreeAuthAccount,
  CNFT,
} from "@captainxyz/solana-core"

const SwapModal = ({ onClose }) => {
  const [amount, setAmount] = useState(50)
  const [loading, setLoading] = useState(false)
  const [usdcBalance, setUsdcBalance] = useState(null)
  const history = useHistory()
  const { publicKey, sendTransaction } = useWallet()
  const adapter = useWallet()

  useEffect(() => {
    getUsdcBalance()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const dispenser = async () => {
    // let's hit a server side dispenser
    let dispenserUrl= `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/USDCforXPCash`
    const res = await fetch(dispenserUrl, {
      method: "POST",
      body: JSON.stringify({
        account: publicKey.toString(),
        amount:amount
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      throw new TypeError("Invalid response")
    }
    const connection = new Connection(process.env.REACT_APP_RPC)
    const data = await res.json()
    const txEncoded = data.transaction
    const tx = Transaction.from(Base64.toUint8Array(txEncoded))
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext()

    try {
      const signature = await sendTransaction(tx, connection, {
        minContextSlot,
      })
      setLoading(true)
      let result = await connection.confirmTransaction({
        blockhash,
        lastValidBlockHeight,
        signature,
      }, "confirmed")
      alert("Swap Succesful")
      setLoading(false)
    } catch (err) {
      console.log("Dispenser or wallet error", err)
    }
  }

  const getUsdcBalance = async () => {
    if (!publicKey) return

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity)

    let mint = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    let tokenAddress = PDA.token(new PublicKey(mint), publicKey)
    let token = await TokenAccount.init(operator, tokenAddress)
    if (token.balance.qty) {
      setUsdcBalance(parseInt(token.balance.qty) / 1000000)
    }
  }

  return (
    <Modal className={styles.swapModal} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.swapTitle}>Swap</div>
        <div className={styles.subTitle}>Turn your USDC into XPCash.</div>
        <div className={classNames(styles.pill, styles.balancePill)}>
          <div className={styles.balancePillLeft}>
            <div className={styles.balancePillFrom}>From</div>
            <div className={styles.balancePillAmount}>{amount}</div>
          </div>

          <div className={styles.balancePillRight}>
            <span className={styles.usdc}>
              {" "}
              <USDC /> &nbsp;USDC{" "}
            </span>
            <div className={styles.balance}>{usdcBalance}</div>
          </div>
        </div>{" "}
        {/* balance pill */}
        <div className={classNames(styles.pill, styles.choosePill)}>
          <div className={styles.chooseAmount}>Choose an amount</div>

          <div className={styles.amountsContainer}>
            <div
              onClick={() => setAmount(25)}
              className={classNames(styles.amountPill, { blue: amount == 25 })}
            >
              <div>25</div>
            </div>

            <div
              onClick={() => setAmount(50)}
              className={classNames(styles.amountPill, { blue: amount == 50 })}
            >
              <div>50</div>
            </div>

            <div
              onClick={() => setAmount(100)}
              className={classNames(styles.amountPill, { blue: amount == 100 })}
            >
              <div>100</div>
            </div>
          </div>
        </div>{" "}
        {/* amounts pill */}
        <div className={styles.downArrowContainer}>
          <DownArrow />
        </div>
        <div className={classNames(styles.pill, styles.bottomPill)}>
          <div className={styles.balancePillLeft}>
            <div className={styles.balancePillFrom}>To</div>
            <div className={styles.balancePillAmount}>{amount}</div>
          </div>

          <div className={styles.usdc}>
            <img src="https://cdn.hngr.co/xpcashlogo.png" alt="xp cash" />{" "}
            &nbsp;XP Cash
          </div>
        </div>
        {loading ? (
          <div className={styles.loading}>
            <img
              style={{height:'30px', width:'30px'}}
              src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
              alt="loading"
            />
          </div>
        ) : (
          <div onClick={()=>dispenser()} className={styles.approveSwap}>
            <div>Approve Swap</div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default SwapModal

const USDC = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4532_8958)">
      <path
        d="M9 18.5C13.9875 18.5 18 14.4875 18 9.5C18 4.51247 13.9875 0.5 9 0.5C4.01247 0.5 0 4.51247 0 9.5C0 14.4875 4.01247 18.5 9 18.5Z"
        fill="#2775CA"
      />
      <path
        d="M11.4752 10.925C11.4752 9.61251 10.6877 9.16251 9.11267 8.97504C7.98767 8.82501 7.76267 8.52504 7.76267 7.99998C7.76267 7.47492 8.1377 7.13751 8.88767 7.13751C9.56267 7.13751 9.9377 7.36251 10.1252 7.92501C10.1627 8.03751 10.2752 8.11248 10.3877 8.11248H10.9876C11.1377 8.11248 11.2502 7.99998 11.2502 7.85004V7.81251C11.1001 6.98748 10.4251 6.35001 9.56267 6.27504V5.37504C9.56267 5.22501 9.45017 5.11251 9.2627 5.07498H8.7002C8.55017 5.07498 8.43767 5.18748 8.40014 5.37504V6.23751C7.27514 6.38754 6.5627 7.13751 6.5627 8.07504C6.5627 9.31254 7.31267 9.79998 8.88767 9.98754C9.9377 10.175 10.2752 10.4 10.2752 11C10.2752 11.6001 9.75014 12.0125 9.0377 12.0125C8.06264 12.0125 7.72514 11.6 7.61264 11.0375C7.5752 10.8875 7.4627 10.8125 7.3502 10.8125H6.71264C6.5627 10.8125 6.4502 10.925 6.4502 11.075V11.1125C6.60014 12.05 7.20017 12.725 8.43767 12.9125V13.8125C8.43767 13.9625 8.55017 14.075 8.73764 14.1125H9.30013C9.45017 14.1125 9.56267 14 9.6002 13.8125V12.9125C10.7252 12.725 11.4752 11.9375 11.4752 10.925Z"
        fill="#FAFAFA"
      />
      <path
        d="M7.08725 14.8625C4.16225 13.8125 2.66222 10.55 3.74978 7.66247C4.31228 6.08747 5.54978 4.8875 7.08725 4.325C7.23728 4.25003 7.31225 4.13753 7.31225 3.94997V3.425C7.31225 3.27497 7.23728 3.16247 7.08725 3.12503C7.04972 3.12503 6.97475 3.12503 6.93722 3.16247C3.37475 4.28747 1.42472 8.07503 2.54972 11.6375C3.22472 13.7375 4.83725 15.35 6.93722 16.025C7.08725 16.1 7.23728 16.025 7.27472 15.875C7.31225 15.8375 7.31225 15.8 7.31225 15.725V15.2C7.31225 15.0875 7.19975 14.9375 7.08725 14.8625ZM11.0623 3.16247C10.9123 3.0875 10.7622 3.16247 10.7248 3.3125C10.6873 3.35003 10.6873 3.38747 10.6873 3.46253V3.9875C10.6873 4.13753 10.7998 4.28747 10.9123 4.36253C13.8373 5.41247 15.3373 8.67497 14.2497 11.5625C13.6872 13.1375 12.4497 14.3375 10.9123 14.9C10.7622 14.975 10.6873 15.0875 10.6873 15.275V15.8C10.6873 15.95 10.7622 16.0625 10.9123 16.1C10.9498 16.1 11.0248 16.1 11.0623 16.0625C14.6248 14.9375 16.5748 11.15 15.4498 7.5875C14.7748 5.45 13.1247 3.83747 11.0623 3.16247Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_4532_8958">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

const DownArrow = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6271 7.00235L14.2877 7.66299C14.5675 7.94273 14.5675 8.39506 14.2877 8.67181L8.50564 14.4569C8.22591 14.7366 7.77357 14.7366 7.49682 14.4569L1.71175 8.67181C1.43202 8.39208 1.43202 7.93975 1.71175 7.66299L2.37239 7.00235C2.6551 6.71965 3.11636 6.7256 3.39311 7.01426L6.8094 10.6002V2.04755C6.8094 1.65176 7.12781 1.33334 7.5236 1.33334H8.47588C8.87167 1.33334 9.19008 1.65176 9.19008 2.04755V10.6002L12.6064 7.01426C12.8831 6.72262 13.3444 6.71667 13.6271 7.00235Z"
      fill="#E5E6FF"
    />
  </svg>
)
