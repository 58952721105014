import classNames from "classnames"
import { Button, Modal } from "../components"
import styles from "./UnsealModal.module.scss"
import { CloseCircle } from "../css/icons"

const UnsealedModal = ({ close }) => (
  <Modal onClose={() => close()} width="medium">
    <div className={styles.unsealModalContainer}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <div className={styles.warningIcon}>⚡️</div>
      <div className={classNames(styles.unsealWarning, styles.unsealed)}>
        Tickets are activated!
      </div>

      <div className={styles.subtext}>
        Enable ticket transfers to conveniently access your tickets on your
        mobile device to guarantee entry to the event
      </div>

      <div className={styles.warningListContainer}>
        <div className={styles.warningListItem}>
          Your security is our top priority. We require a wallet signature to
          enable transfers to ensure that only authorized individuals have
          access.
        </div>
        <div className={styles.warningListItem}>
          Enable transfers with your wallet and follow the provided instructions
          to claim your ticket securely.
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="gray" onClick={() => close()}>
          Not Now
        </Button>
        <Button variant="blue">Enable Transfers</Button>
      </div>
    </div>
  </Modal>
)

export default UnsealedModal
