export const FilledCircle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.33276C11.6827 1.33276 14.668 4.3181 14.668 8.00076C14.668 11.6828 11.6827 14.6681 8 14.6681C4.31733 14.6681 1.332 11.6828 1.332 8.00076C1.332 4.3181 4.31733 1.33276 8 1.33276ZM8 2.33276C7.25054 2.32464 6.50691 2.46523 5.81215 2.74641C5.11738 3.02759 4.48529 3.44378 3.95244 3.97087C3.4196 4.49797 2.99658 5.12552 2.70788 5.81719C2.41918 6.50886 2.27053 7.25093 2.27053 8.00043C2.27053 8.74994 2.41918 9.492 2.70788 10.1837C2.99658 10.8753 3.4196 11.5029 3.95244 12.03C4.48529 12.5571 5.11738 12.9733 5.81215 13.2544C6.50691 13.5356 7.25054 13.6762 8 13.6681C9.48802 13.6453 10.9074 13.0381 11.9516 11.9778C12.9958 10.9174 13.5811 9.48895 13.5811 8.00076C13.5811 6.51257 12.9958 5.08408 11.9516 4.02374C10.9074 2.96339 9.48802 2.35559 8 2.33276ZM7.99733 3.99943C9.05785 3.99943 10.0749 4.42072 10.8248 5.17061C11.5747 5.92051 11.996 6.93758 11.996 7.9981C11.996 9.05861 11.5747 10.0757 10.8248 10.8256C10.0749 11.5755 9.05785 11.9968 7.99733 11.9968C6.93682 11.9968 5.91975 11.5755 5.16985 10.8256C4.41995 10.0757 3.99867 9.05861 3.99867 7.9981C3.99867 6.93758 4.41995 5.92051 5.16985 5.17061C5.91975 4.42072 6.93682 3.99943 7.99733 3.99943Z"
      fill="#19B2E6"
    />
  </svg>
)
