import { useHistory } from "react-router-dom"
import { useState } from "react"
import { Button, Modal } from "../components"
import styles from "./GoldToken.module.scss"
import { useWallet } from "@solana/wallet-adapter-react"
import { Base64 } from "js-base64"
import { PublicKey, Connection, Transaction } from "@solana/web3.js" // eslint-disable-line no-unused-vars

const GoldToken = ({ onClose, color }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { publicKey, sendTransaction } = useWallet()

  const dispenser = async () => {
    // let's hit a server side dispenser
    let dispenserUrl
    if (color === "crystal") {
      dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/TensorSeason2Silver_YUUPBW`
    } else {
      dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/TensorSeason2Gold_JUYQNY`
    }
    const res = await fetch(dispenserUrl, {
      method: "POST",
      body: JSON.stringify({
        account: publicKey.toString(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      throw new TypeError("Invalid response")
    }
    const connection = new Connection(process.env.REACT_APP_RPC)
    const data = await res.json()
    const txEncoded = data.transaction
    const tx = Transaction.from(Base64.toUint8Array(txEncoded))
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext()

    try {
      const signature = await sendTransaction(tx, connection, {
        minContextSlot,
      })
      setLoading(true)
      let result = await connection.confirmTransaction(
        {
          blockhash,
          lastValidBlockHeight,
          signature,
        },
        "confirmed"
      )
      history.push("/gold?color=" + color)
    } catch (err) {
      console.log("Dispenser or wallet error", err)
    }
  }

  return (
    <Modal onClose={() => onClose()} className={styles.goldModal}>
      <div className={styles.container}>
        {loading ? (
          <img src="https://cdn.hngr.co/tamperproof/landingspinner.gif" />
        ) : (
          <>
            <div className={styles.imagePlaceholder}>
              <img
                src={
                  color === "gold"
                    ? "https://hngr-icons.s3.amazonaws.com/tensor_nft_images/XP+Tile+Gold+2.gif"
                    : "https://cdn.hngr.co/tensor_nft_images/xpcrystal.gif"
                }
                id="tokenImage"
                alt="Tile"
              />
            </div>

            <div className={styles.content}>
              {color === "gold" ? (
                <h1>Claim $200 XP Cash</h1>
              ) : (
                <h1>Claim $40 XP Cash</h1>
              )}
              <p>
                You’re in the XP beta! Redeem your cash and get tickets to your
                favorite live events!
              </p>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  variant="beigeOutline"
                  onClick={() => onClose()}
                >
                  Not Now
                </Button>
                <Button
                  className={styles.button}
                  variant="blue"
                  onClick={() => {
                    dispenser()
                    //history.push("/gold")
                    //onClose()
                  }}
                >
                  Cash Out Chip
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default GoldToken
