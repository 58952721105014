import classNames from "classnames"
import styles from "./Button.module.scss"

const Button = ({
  children,
  className,
  compact,
  disabled,
  fullWidth,
  leftIcon,
  loading,
  onClick,
  rightIcon,
  rightIconOnClick,
  rounded,
  variant = "gradient", // blue, gradient, gray, beige
  outline,
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        compact && styles.compact,
        rounded && styles.rounded,
        variant && styles[variant],
        outline && styles.outline,
        fullWidth && styles.fullWidth,
        className
      )}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
      {children}
      {rightIcon && (
        <span
          className={styles.rightIcon}
          onClick={(e) => rightIconOnClick && rightIconOnClick(e)}
        >
          {rightIcon}
        </span>
      )}
    </button>
  )
}

export default Button
