import classNames from "classnames"
import styles from "./Tag.module.scss"

const Tag = (
  { content, variant } // sealed, ready to claim, claimed, neutral, bad
) => (
  <div className={classNames(styles.tag, styles[`${variant}Tag`])}>
    <div>{content}</div>
  </div>
)

export default Tag
