export const AngleLeft = ({ onClick }) => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => onClick && onClick()}
  >
    <path
      d="M1.1265 4.61128L5.57708 0.160928C5.79165 -0.0536428 6.13973 -0.0536428 6.3543 0.160928L6.87344 0.680067C7.08778 0.894409 7.08801 1.24157 6.87436 1.45637L3.3471 4.99989L6.87413 8.54363C7.08801 8.75843 7.08755 9.10559 6.87321 9.31993L6.35407 9.83907C6.1395 10.0536 5.79142 10.0536 5.57685 9.83907L1.1265 5.3885C0.911933 5.17392 0.911933 4.82585 1.1265 4.61128V4.61128Z"
      fill="#FAFAFA"
    />
  </svg>
)
