export const Discord = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_549_1456)">
      <path
        d="M15.7477 3.16763C14.5672 2.615 13.3184 2.22233 12.0342 2C11.8742 2.28936 11.6873 2.67854 11.5585 2.98816C10.1739 2.77985 8.802 2.77985 7.44286 2.98816C7.31405 2.67861 7.12294 2.28936 6.9615 2C5.67605 2.22243 4.42615 2.6161 3.24514 3.17051C0.895011 6.72391 0.257909 10.1889 0.576425 13.6049C2.13518 14.7696 3.64578 15.4771 5.13092 15.9401C5.50001 15.4323 5.82628 14.8946 6.10636 14.3327C5.57313 14.1295 5.05908 13.8792 4.57032 13.5847C4.69894 13.4893 4.82453 13.3899 4.94691 13.2866C7.90861 14.6727 11.1267 14.6727 14.0531 13.2866C14.176 13.3892 14.3015 13.4886 14.4296 13.5847C13.9401 13.88 13.425 14.1308 12.8907 14.3341C13.1724 14.8984 13.4981 15.4366 13.8662 15.9415C15.3527 15.4786 16.8647 14.7711 18.4235 13.6049C18.7973 9.64499 17.7851 6.21175 15.7477 3.16763ZM6.50989 11.5042C5.62078 11.5042 4.89164 10.6737 4.89164 9.66236C4.89164 8.65106 5.60525 7.81915 6.50989 7.81915C7.4146 7.81915 8.14367 8.64958 8.12813 9.66236C8.12953 10.6737 7.4146 11.5042 6.50989 11.5042ZM12.49 11.5042C11.6009 11.5042 10.8719 10.6737 10.8719 9.66236C10.8719 8.65106 11.5854 7.81915 12.49 7.81915C13.3947 7.81915 14.1238 8.64958 14.1083 9.66236C14.1083 10.6737 13.3947 11.5042 12.49 11.5042Z"
        fill="#FFE9D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_549_1456">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
