export const Guitar = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4234 1.84882L12.6518 1.07717C12.5744 0.999759 12.4825 0.938356 12.3814 0.896463C12.2803 0.85457 12.1719 0.833008 12.0624 0.833008C11.953 0.833008 11.8446 0.85457 11.7435 0.896463C11.6423 0.938356 11.5505 0.999759 11.4731 1.07717L10.2678 2.28374C10.1668 2.38449 10.0907 2.50748 10.0457 2.64287L9.72898 3.5937L7.74294 5.57974C7.42626 5.35161 7.07884 5.16983 6.69861 5.08128C5.82748 4.87867 4.95373 5.05836 4.35787 5.65423C4.11479 5.89829 3.934 6.19726 3.83076 6.52589C3.6745 7.00924 3.2263 7.37723 2.73304 7.42333C2.11582 7.48245 1.5421 7.72152 1.10249 8.16399C-0.0861129 9.34921 0.1277 11.4847 1.57257 12.9288C3.01743 14.3729 5.1514 14.5839 6.33792 13.3994C6.78065 12.9598 7.0192 12.3863 7.07884 11.7663C7.12468 11.2754 7.49267 10.8253 7.9755 10.6688C8.30419 10.5654 8.60322 10.3845 8.84742 10.1414C9.44303 9.54558 9.62194 8.67365 9.42037 7.80408C9.33208 7.42281 9.1503 7.07487 8.92139 6.75715L10.9069 4.77163L11.857 4.45495C11.9923 4.40991 12.1153 4.33383 12.2161 4.2328L13.4227 3.02649C13.5788 2.87034 13.6665 2.65862 13.6667 2.43781C13.6668 2.21701 13.5793 2.00518 13.4234 1.84882ZM5.75039 10.0003C5.50315 10.0003 5.26146 9.92697 5.05589 9.78961C4.85032 9.65225 4.69009 9.45702 4.59548 9.2286C4.50086 9.00018 4.47611 8.74883 4.52434 8.50634C4.57258 8.26386 4.69163 8.04112 4.86646 7.86629C5.04128 7.69147 5.26402 7.57241 5.50651 7.52418C5.749 7.47594 6.00035 7.5007 6.22877 7.59531C6.45719 7.68993 6.65242 7.85015 6.78978 8.05572C6.92714 8.26129 7.00045 8.50298 7.00045 8.75022C7.00045 9.08176 6.86875 9.39972 6.63432 9.63415C6.39988 9.86858 6.08193 10.0003 5.75039 10.0003Z"
      fill="#19B2E6"
    />
  </svg>
)
