import React, { useState } from "react"
import Input from "../Input/Input"
import Button from "../Button/Button"
import styles from "./Footer.module.scss"
import { Discord, Twitter } from "../../css/icons"
import { useHistory } from "react-router-dom"

const Footer = ({ velvetFooter, velvetOnClick }) => {
  const history = useHistory()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  const subscribe = async () => {
    if (validEmail) {
      await fetch("https://formspree.io/f/myyqyvpg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
        }),
      })
    }
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContainerInner}>
        <div className={styles.footerLogoColumn}>
          <img
            className={styles.footerLogo}
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() =>
              (window.location.href = "https://www.ticketdex.xyz/")
            }
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp_logo_4+1.png"
          />
        </div>
        <div className={styles.footerLinkContainer}>
          <h3>XP Tickets</h3>
          <a href="http://sell.xp.xyz" target="_blank" rel="noreferrer">
            Sell Your Tickets
          </a>
          <div
            className={styles.footerLink}
            onClick={() => {
              if (velvetFooter) {
                velvetOnClick()
              } else {
                history.push("/events")
              }
            }}
          >
            Upcoming Events
          </div>
          <a href="https://cdn.hngr.co/xptc.pdf" target="_blank" rel="noreferrer">
            Terms &amp; Conditions
          </a>

        </div>
        <div className={styles.footerLinkContainer}>
          <h3>Stay in the Know</h3>
          <a
            className={styles.socialLink}
            href="https://twitter.com/xpverse"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter />
          </a>
          <a
            className={styles.socialLink}
            href="https://discord.gg/GYrHjHjybg"
            target="_blank"
            rel="noreferrer"
          >
            <Discord />
          </a>
        </div>
        <div className={styles.footerForm}>
          <h3>Get Early Access</h3>
          <Input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              validateEmail(e.target.value)
            }}
          />
          <Button onClick={() => subscribe()} fullWidth variant="beige">
            Keep Me Updated
          </Button>
        </div>
      </div>
      <div className={styles.footerCopyright}>
        <a href="https://www.gocaptain.com/" className={styles.captain}>
          XP by Captain
        </a>{" "}
        | 2023 All Rights Reserved
      </div>
    </div>
  )
}

export default Footer
