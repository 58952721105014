import { useState } from "react"
import SwapModal from "../components/SwapModal/SwapModal"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory } from "react-router-dom"
import classNames from "classnames"
import { Button, Layout } from "../components"
import styles from "./CheckoutPage.module.scss"
import { useEffect } from "react"
import { getEventById } from "../reducers/eventSlice"
import { clearTicketGroups, getTicketGroupById } from "../reducers/ticketSlice"
import { formatDate } from "../helpers/formatDate"
import {
  AngleDown,
  AngleRight,
  CheckCircle,
  Circle,
  FilledCircle,
  Play,
  Power,
  ShieldCheck,
  Ticket,
  Tickets,
  Trunk,
  USDC,
  Venue,
  Wallet,
} from "../css/icons"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"
import { AuctionHouseAccount } from "@captainxyz/marketplace"
import postToSlack from "../postToSlack"

const { PublicKey } = require("@solana/web3.js")

const CURRENCIES = {
  "6c9b73kDgkoBsQKcPVPXewdytbUqUKSQcAWQfff16wr7": {
    name: "Clubs",
  },
  EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: {
    name: "USDC",
  },
  A8ieiDudXNC3Cj2t3jGqivop1fUqccWN3h7XJXzBsc13: {
    name: "XP Cash",
  },
}

const CheckoutInfo = () => (
  <div className={classNames(styles.container, styles.ticketInfo)}>
    <div className={styles.ticketInfoItem}>
      <Trunk />
      <div>
        <div className={styles.ticketInfoItemHeader}>Receive your tpNFT</div>
        <div className={styles.ticketInfoItemDescription}>
          After checkout, you will receive an NFT in your wallet containing
          access to your ticket.
        </div>
      </div>
    </div>
    <div className={styles.ticketInfoItem}>
      <ShieldCheck />
      <div>
        <div className={styles.ticketInfoItemHeader}>XP Guarantee</div>
        <div className={styles.ticketInfoItemDescription}>
          Your ticket purchase is protected by our authenticity guarantee.
        </div>
      </div>
    </div>
    <div className={styles.ticketInfoItem}>
      <Power />
      <div>
        <div className={styles.ticketInfoItemHeader}>
          Activate after purchase
        </div>
        <div className={styles.ticketInfoItemDescription}>
          Activate and claim your ticket separately to complete the process.
          Instructions will be provided.
        </div>
      </div>
    </div>
  </div>
)

const CheckoutPage = () => {
  const [buying, setBuying] = useState(false)
  const [minting, setMinting] = useState(false)
  const [minted, setMinted] = useState(false)
  const [showSwapModal, setShowSwapModal] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [showCurrencies, setShowCurrencies] = useState(false)
  const [interstitialActiveStep, setInterstitialActiveStep] = useState(1)
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const adapter = useWallet()

  const selectedEvent = useSelector((state) => state.events.selectedEvent)
  const user = useSelector((state) => state.user.user)
  const selectedTicketGroup = useSelector(
    (state) => state.tickets.selectedTicketGroup
  )

  // TODO verify quantity is valid
  const query = new URLSearchParams(window.location.search)
  const quantity = query.get("quantity")

  let interval
  let refreshInterval

  // todo: add an endpoint that allows you to get a ticket group by id or by mint so that
  // if you reload this page without coming from the event, it can still fetch the correct
  // ticket group data. this probably isn't that relevant for the user but will make development
  // much easier
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!selectedEvent) {
      dispatch(getEventById(params.event_id))
    }
    if (!selectedTicketGroup) {
      //alert('about to dispatch get ticket group')
      //alert(params.ticket_group_id + " is ticket group id")
      dispatch(getTicketGroupById(params.ticket_group_id))
    }

    window.TICKET_GROUP_ID = params.ticket_group_id
    return () => {
      clearInterval(refreshInterval)
      dispatch(clearTicketGroups())
      window.TICKET_GROUP_ID = null
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("selected ticket group change", selectedTicketGroup)
    if (selectedTicketGroup?.listings?.length) {
      setMinting(false)
      setMinted(true)
      clearInterval(interval)
    }
    if (selectedTicketGroup?.listings?.length && !selectedCurrency) {
      setSelectedCurrency(selectedTicketGroup.listings[0].currency_mint)
    }
  }, [selectedTicketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  const recordBought = async (address) => {
    let url = `${process.env.REACT_APP_HNGR_API}/stagehand/bought`
    let headers = {
      "Content-Type": "application/json",
    }

    let listing
    for (let i in selectedTicketGroup.listings) {
      if (selectedTicketGroup.listings[i].currency_mint === selectedCurrency) {
        listing = selectedTicketGroup.listings[i]
      }
    }

    let params = {
      sold_to: address,
      listing_id: listing.listing_id,
    }

    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  const mintNFT = async () => {
    let msg = `${user.publicKey.toString()} (${
      user.loginMethod
    }) is minting an NFT for ${selectedEvent.title}`
    postToSlack(msg, "xp")

    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/ticketgroup/mint-nft`
    let headers = {
      "Content-Type": "application/json",
    }

    let body = {
      event_id: params.event_id,
      ticket_group_id: params.ticket_group_id,
      quantity: quantity,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(body),
      })
      resp = await resp.json()
      console.log("resp", resp)
      if (!resp.success) {
        alert(resp.message)
        return
      }
      console.log("minting resp", resp)
      setMinting(true)
      postToSlack(msg, "xp")
      refresh()
    } catch (err) {
      let msg = `${user.publicKey.toString()} (${
        user.loginMethod
      }) had a minting error an NFT for ${selectedEvent.title}!`
      postToSlack(msg, "xp")
      console.log("minting error", err)
      setMinting(false)
      alert("Error minting")
    }
  }

  const refresh = async () => {
    setInterstitialActiveStep(interstitialActiveStep + 1)
    if (minted) {
      console.log("minted")
      let msg = `${user.publicKey.toString()} (${
        user.loginMethod
      }) minted an NFT for ${selectedEvent.title}!`
      return
    }
    if (
      !window.TICKET_GROUP_ID ||
      window.TICKET_GROUP_ID !== params.ticket_group_id
    ) {
      console.log("unmounted")
      return
    }
    console.log("refreshing for ", params.ticket_group_id)
    dispatch(getTicketGroupById(params.ticket_group_id))
    if (!selectedTicketGroup?.listings?.length) {
      refreshInterval = setTimeout(() => refresh(), 5000)
    } else {
      setMinting(false)
      console.log("already listed!")
    }
  }

  const buyNFTServerSide = async () => {
    setBuying(true)

    let listing
    for (let i in selectedTicketGroup.listings) {
      if (selectedTicketGroup.listings[i].currency_mint === selectedCurrency) {
        listing = selectedTicketGroup.listings[i]
      }
    }

    let data = {
      diner_uuid: user.uuid,
      listing_id: listing.listing_id,
    }

    console.log("listing is", listing)
    console.log("selected ticket group is", selectedTicketGroup)

    let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexBuy`

    let headers = {
      "Content-Type": "application/json",
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      if (!resp.success) {
        if (resp.error) alert(resp.error)
        else alert("Unknown error")
        setBuying(false)
      }
    } catch (err) {
      console.log("SSW buy error", err)
      alert("Unknown error trying to buy")
      setBuying(false)
      return
    }

    setBuying(false)
    recordBought(user.publicKey.toString())
    history.push(
      `/checkout/${params.event_id}/${params.ticket_group_id}/success`
    )
  }

  const buyNFT = async () => {
    if (!selectedTicketGroup) {
      alert("Selected Ticket Group not set")
      return
    }


    let url2 = `${process.env.REACT_APP_HNGR_API}/api/stagehand/enabled`

    let headers = {
      "Content-Type": "application/json",
    }
    let resp = await fetch(url2, {
      method: "get",
      headers: headers,
    })
    resp = await resp.json()
    if(!resp.enabled){
      alert("Ticket sales are disabled")
      return
    }
   



    if (user.uuid) {
      alert("Buying on the backend")
      buyNFTServerSide()
      return
    }

    let msg = `${user.publicKey.toString()} (${
      user.loginMethod
    }) is going to buy an NFT for ${selectedEvent.title}!`

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    // find the right listing by currency
    let listing
    for (let i in selectedTicketGroup.listings) {
      if (selectedTicketGroup.listings[i].currency_mint === selectedCurrency) {
        listing = selectedTicketGroup.listings[i]
      }
    }

    console.log("listing at buy", listing)
    console.log("selected ticket group at buy", selectedTicketGroup)

    let price = listing.price
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity)
    const sellerAuctionhouseAddress = new PublicKey(listing.ah_address)

    let mint = new PublicKey(selectedTicketGroup.mint)
    let tokenAddress = PDA.token(
      mint,
      new PublicKey("FqyiygsWRLc2jsMWNdME8JCYyizcC4KEa6y18AmW4JQb")
    )
    let sellerNFT = await TokenAccount.init(operator, tokenAddress)
    const buyerAH = await AuctionHouseAccount.init(
      operator,
      sellerAuctionhouseAddress
    )

    const currency = new PublicKey(listing.currency_mint)
    let currencyAddress = PDA.token(
      currency,
      new PublicKey("FqyiygsWRLc2jsMWNdME8JCYyizcC4KEa6y18AmW4JQb") //oracle
    )

    let currencyAccount = await TokenAccount.init(operator, currencyAddress)
    let currencyCurrency = currencyAccount.metadata.currency
    price = currencyCurrency.amountFromValue(price)

    setBuying(true)
    try {
      const [buyerNFT, result] = await buyerAH.buyNFT(sellerNFT, price)
      setBuying(false)
      if (result.signature) {
        let msg = `<@wilson29> <@saunders3000> <@kevan> ${user.publicKey.toString()} (${
          user.loginMethod
        }) bought an NFT for ${selectedEvent.title}! mint:(${
          selectedTicketGroup?.mint
        })`
        postToSlack(msg, "xp")
        recordBought(adapter.publicKey.toString())
        history.push(
          `/checkout/${params.event_id}/${params.ticket_group_id}/success`
        )
      } else {
        let msg = `${user.publicKey.toString()} (${
          user.loginMethod
        }) had a buying error for ${selectedEvent.title}!`
        postToSlack(msg, "xp")
        alert("Buying may have failed. Check your wallet.")
      }
    } catch (err) {
      let msg = `${user.publicKey.toString()} (${
        user.loginMethod
      }) had a buying error(2) for ${selectedEvent.title}!`
      postToSlack(msg, "xp")
      alert("Buying may have failed. Check your wallet.")
      console.log(err)
      setBuying(false)
    }
  }

  return (
    <Layout>
      <div className={styles.pageContainer}>
        {buying || (minting && !selectedTicketGroup.listed_at) ? (
          <div className={styles.interstitialContainer}>
            <div className={styles.interstitialContent}>
              <img
                src={
                  "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/blob.gif"
                }
                alt="blob"
              />
              <div className={styles.interstitialText}>
                <h1>Preparing your tickets</h1>
                <div className={styles.interstitialItem}>
                  <div
                    className={classNames(
                      styles.interstitialLabel,
                      interstitialActiveStep === 1 &&
                        styles.interstitialLabelActive,
                      interstitialActiveStep > 1 &&
                        styles.interstitialLabelComplete
                    )}
                  >
                    Triple checking availability{" "}
                    {interstitialActiveStep > 1 && <CheckCircle />}
                  </div>
                  <div
                    className={classNames(
                      styles.interstitialDescription,
                      interstitialActiveStep >= 1 &&
                        styles.interstitialDescriptionActive
                    )}
                  >
                    We do an extra check on each ticket to ensure you never miss
                    your event
                  </div>
                </div>
                <div className={styles.interstitialItem}>
                  <div
                    className={classNames(
                      styles.interstitialLabel,
                      interstitialActiveStep === 2 &&
                        styles.interstitialLabelActive,
                      interstitialActiveStep > 2 &&
                        styles.interstitialLabelComplete
                    )}
                  >
                    Securely Sealing{" "}
                    {interstitialActiveStep > 2 && <CheckCircle />}
                  </div>
                  <div
                    className={classNames(
                      styles.interstitialDescription,
                      interstitialActiveStep >= 2 &&
                        styles.interstitialDescriptionActive
                    )}
                  >
                    Sealing a claim to the ticket(s) on the Solana blockchain
                  </div>
                </div>
                <div className={styles.interstitialItem}>
                  <div
                    className={classNames(
                      styles.interstitialLabel,
                      interstitialActiveStep === 3 &&
                        styles.interstitialLabelActive
                    )}
                  >
                    The tickets are ready for purchase
                  </div>
                  <div
                    className={classNames(
                      styles.interstitialDescription,
                      interstitialActiveStep >= 3 &&
                        styles.interstitialDescriptionActive
                    )}
                  >
                    Complete your purchase on the next page{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.leftColumn}>
              <div className={classNames(styles.container, styles.showInfo)}>
                <div className={styles.showInfoHeader}>
                  <div>
                    <div className={styles.date}>
                      {selectedEvent?.date_formatted}
                    </div>
                    <div className={styles.title}>
                      {selectedEvent?.short_title || selectedEvent?.title}
                    </div>
                    <div className={styles.venue}>
                      <Venue />
                      {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                      {selectedEvent?.venue_state}
                    </div>
                  </div>
                </div>
                <div className={styles.showImageContainer}>
                  <img
                    className={styles.showImage}
                    alt={selectedEvent?.title}
                    src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
                  />
                </div>
              </div>
              {window.innerWidth > 960 && <CheckoutInfo />}
            </div>
            <div
              className={classNames(
                styles.container,
                styles.checkoutContainer,
                params.success && styles.successContainer
              )}
            >
              {params.success ? (
                <div className={styles.header}>
                  <div>
                    <h1>Purchase Successful!</h1>
                    <p>
                      Store your tickets in your wallet and transfer to your
                      mobile device before use.
                    </p>
                  </div>
                  <CheckCircle />
                </div>
              ) : (
                <div className={styles.header}>
                  <div>
                    <h1>Reserve your tickets</h1>
                    <p>
                      Place a hold on your preferred tickets and complete the
                      purchase in the following screens.
                    </p>
                  </div>
                </div>
              )}
              <div className={styles.metadataItem}>
                <div className={styles.metadataInfo}>
                  <div className={styles.iconContainer}>
                    <Tickets />
                  </div>
                  <div>
                    <div className={styles.metadataItemHeader}>Issuer</div>
                    <div className={styles.metadataItemValue}>
                      Gold Coast Tickets
                    </div>
                  </div>
                </div>
                <AngleRight className={styles.angle} />
              </div>
              <div
                className={classNames(styles.metadataItem, styles.ticketCount)}
              >
                <div className={styles.metadataInfo}>
                  <img
                    src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/cubeplaceholder.png"
                    alt="Tickets"
                    className={styles.ticketImage}
                  />
                  <div className={styles.metadataContent}>
                    <div
                      className={classNames(
                        styles.metadataItemValue,
                        styles.metadataWithMargin,
                        styles.metadataLarge
                      )}
                    >
                      {selectedTicketGroup?.section &&
                        `Sec ${selectedTicketGroup?.section}, `}
                      Row {selectedTicketGroup?.row}
                    </div>
                    <div className={styles.metadataItemDetail}>
                      {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                      {selectedEvent?.venue_state}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.metadataItemDetail,
                    styles.metadataTicketCount
                  )}
                >
                  <Ticket />
                  {quantity} {quantity === 1 ? "Ticket" : "Tickets"}
                </div>
              </div>

              {!params.success && (
                <div
                  className={classNames(
                    styles.metadataItem,
                    styles.metadataColumn
                  )}
                >
                  <div className={styles.metadataRow}>
                    <div className={styles.metadataItemDetail}>Tickets</div>
                    <div className={styles.metadataItemDetail}>
                      {quantity} x{" $"}
                      {selectedTicketGroup?.cost}
                    </div>
                  </div>
                  <div className={styles.metadataRow}>
                    <div
                      className={classNames(
                        styles.metadataItemValue,
                        styles.metadataLarge
                      )}
                    >
                      {minted ? "Total" : "Subtotal (Purchase later)"}
                    </div>
                    <div
                      className={classNames(
                        styles.metadataItemValue,
                        styles.metadataLarge
                      )}
                    >
                      <USDC />${quantity * selectedTicketGroup?.cost}
                    </div>
                  </div>

                  {minted && (
                    <div className={styles.metadataRow}>
                      <div className={styles.metadataItemDetail}>
                        Select Currency
                      </div>
                      <div className={styles.selectContainer}>
                        <div
                          className={styles.select}
                          onClick={() => setShowCurrencies(true)}
                        >
                          {selectedCurrency &&
                            CURRENCIES[selectedCurrency]?.name}
                          <AngleDown className={styles.iconRight} />
                        </div>
                        {showCurrencies && (
                          <div className={styles.selectOptions}>
                            {selectedTicketGroup?.listings?.map((listing) => {
                              console.log(listing, "the listings")
                              return (
                                <div
                                  className={classNames(
                                    styles.selectItem,
                                    selectedCurrency ===
                                      listing.currency_mint && styles.selected
                                  )}
                                  onClick={() => {
                                    setSelectedCurrency(listing.currency_mint)
                                    setShowCurrencies(false)
                                  }}
                                >
                                  {CURRENCIES[listing.currency_mint].name}
                                  {selectedCurrency ===
                                  listing.currency_mint ? (
                                    <FilledCircle />
                                  ) : (
                                    <Circle />
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {minted && (
                    <Button
                      className={styles.checkoutButton}
                      variant="blue"
                      onClick={() => setShowSwapModal(true)}
                    >
                      Swap USDC for XP Cash
                    </Button>
                  )}
                </div>
              )}
              {params.success ? (
                <div className={styles.buttonContainer}>
                  <Link to="/holder/NFTs">
                    <Button fullWidth variant="gray" leftIcon={<Wallet />}>
                      View in Wallet
                    </Button>
                  </Link>
                  <Link to={`/holder/NFT/${selectedTicketGroup?.mint}`}>
                    <Button fullWidth variant="blue" leftIcon={<Play />}>
                      Activate Tickets
                    </Button>
                  </Link>
                </div>
              ) : (
                <>
                  {selectedTicketGroup?.listings?.length ? (
                    <Button
                      className={styles.checkoutButton}
                      fullWidth
                      variant="blue"
                      onClick={buyNFT}
                    >
                      Checkout
                    </Button>
                  ) : (
                    <Button fullWidth variant="blue" onClick={mintNFT}>
                      Reserve Tickets
                    </Button>
                  )}
                </>
              )}
            </div>

            {window.innerWidth < 960 && <CheckoutInfo />}
          </>
        )}
        {showSwapModal && <SwapModal onClose={() => setShowSwapModal(false)} />}
      </div>
    </Layout>
  )
}

export default CheckoutPage
