export const Phone = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.675 0.75H5.325C4.45547 0.75 3.75 1.44287 3.75 2.29688V15.7031C3.75 16.5571 4.45547 17.25 5.325 17.25H12.675C13.5445 17.25 14.25 16.5571 14.25 15.7031V2.29688C14.25 1.44287 13.5445 0.75 12.675 0.75ZM9 16.2188C8.41922 16.2188 7.95 15.7579 7.95 15.1875C7.95 14.6171 8.41922 14.1562 9 14.1562C9.58078 14.1562 10.05 14.6171 10.05 15.1875C10.05 15.7579 9.58078 16.2188 9 16.2188ZM12.675 12.7383C12.675 12.951 12.4978 13.125 12.2812 13.125H5.71875C5.50219 13.125 5.325 12.951 5.325 12.7383V2.68359C5.325 2.4709 5.50219 2.29688 5.71875 2.29688H12.2812C12.4978 2.29688 12.675 2.4709 12.675 2.68359V12.7383Z"
      fill="#FAFAFA"
    />
  </svg>
)
