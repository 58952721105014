import React from "react"
import { createRoot } from "react-dom/client"
import { Provider as ReduxProvider, useSelector } from "react-redux"
import { BrowserRouter, Route } from "react-router-dom"
import WalletContext from "./contexts/WalletContext"
import { store } from "./store"
import "./css/global.scss"

// routes
import Home from "./Home/Home"

import HolderNFTs from "./Holder/NFTs"
import HolderNFT from "./Holder/NFT"

import EventList from "./Events/EventList"
import EventPage from "./Events/EventPage"
import ArtistPage from "./Artist/ArtistPage"
import CheckoutPage from "./Checkout/CheckoutPage"
import VelvetRope from "./Home/VelvetRope"
import GoldTokenRedeemed from "./Home/GoldTokenRedeemed"

import * as Sentry from "@sentry/react"
import LogRocket from "logrocket"


if (process.env.REACT_APP_HNGR_SENTRY_DSN){
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET)
}

const App = () => {
  const user = useSelector((state) => state.user.user)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Route
        path="/"
        exact
        component={user?.authenticated ? Home : VelvetRope}
      />

      <Route
        path="/gold"
        exact
        component={user?.authenticated ? GoldTokenRedeemed : VelvetRope}
      />

      <Route
        path="/holder/NFTs"
        exact
        component={user?.authenticated ? HolderNFTs : VelvetRope}
      />

      <Route
        path="/holder/NFT/:mint"
        exact
        component={user?.authenticated ? HolderNFT : VelvetRope}
      />

      <Route
        path="/events"
        exact
        component={user?.authenticated ? EventList : VelvetRope}
      />

      <Route
        path="/events/search/:query"
        exact
        component={user?.authenticated ? EventList : VelvetRope}
      />

      <Route
        path="/event/:event_id"
        exact
        component={user?.authenticated ? EventPage : VelvetRope}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_id"
        exact
        component={user?.authenticated ? CheckoutPage : VelvetRope}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_id/:success"
        exact
        component={user?.authenticated ? CheckoutPage : VelvetRope}
      />

      <Route
        path="/artist/:artist_id"
        exact
        component={user?.authenticated ? ArtistPage : VelvetRope}
      />
    </div>
  )
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <WalletContext>
        <App />
      </WalletContext>
    </BrowserRouter>
  </ReduxProvider>
)
