export const ShieldCheck = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2632_22691)">
      <path
        d="M16.4004 2.94256L9.65039 0.130056C9.44478 0.0447595 9.22436 0.000854492 9.00176 0.000854492C8.77915 0.000854492 8.55874 0.0447595 8.35313 0.130056L1.60312 2.94256C0.973828 3.20271 0.5625 3.81795 0.5625 4.49998C0.5625 11.4785 4.58789 16.3019 8.34961 17.8699C8.76445 18.0422 9.23203 18.0422 9.64687 17.8699C12.6598 16.6148 17.4375 12.2801 17.4375 4.49998C17.4375 3.81795 17.0262 3.20271 16.4004 2.94256ZM14.741 6.9574L8.27227 13.4261C8.0543 13.6441 7.6957 13.6441 7.47773 13.4261L3.82148 9.7699C3.60352 9.55193 3.60352 9.19334 3.82148 8.97537L4.61602 8.18084C4.83398 7.96287 5.19258 7.96287 5.41055 8.18084L7.875 10.6453L13.152 5.36834C13.3699 5.15037 13.7285 5.15037 13.9465 5.36834L14.741 6.16287C14.9625 6.38435 14.9625 6.73943 14.741 6.9574Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_2632_22691">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
