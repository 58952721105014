export const Info = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00016 1.33334C4.31852 1.33334 1.3335 4.31944 1.3335 8C1.3335 11.6827 4.31852 14.6667 8.00016 14.6667C11.6818 14.6667 14.6668 11.6827 14.6668 8C14.6668 4.31944 11.6818 1.33334 8.00016 1.33334ZM8.00016 4.29033C8.62371 4.29033 9.1292 4.79581 9.1292 5.41936C9.1292 6.04291 8.62371 6.54839 8.00016 6.54839C7.37661 6.54839 6.87113 6.04291 6.87113 5.41936C6.87113 4.79581 7.37661 4.29033 8.00016 4.29033ZM9.50554 11.1183C9.50554 11.2964 9.3611 11.4409 9.18296 11.4409H6.81737C6.63922 11.4409 6.49479 11.2964 6.49479 11.1183V10.4731C6.49479 10.295 6.63922 10.1505 6.81737 10.1505H7.13995V8.43011H6.81737C6.63922 8.43011 6.49479 8.28567 6.49479 8.10753V7.46237C6.49479 7.28422 6.63922 7.13979 6.81737 7.13979H8.5378C8.71594 7.13979 8.86038 7.28422 8.86038 7.46237V10.1505H9.18296C9.3611 10.1505 9.50554 10.295 9.50554 10.4731V11.1183Z"
      fill="#FAFAFA"
    />
  </svg>
)
