import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  ticketGroups: [],
  ticketGroupsLoading: false,
  selectedTicketGroup: null,
  selectedTicketGroupLoading: false,
  selectedTicketGroupError: false,
}

let headers = {
  "Content-Type": "application/json",
}

export const getAllTicketGroupsForEvent = createAsyncThunk(
  "tickets/getAllTicketGroupsForEvent",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/event/tickets"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: data }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response.ticket_groups
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// placeholder - this endpoint doesn't exist yet
export const getTicketGroupById = createAsyncThunk(
  "tickets/getTicketGroupById",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/ticketgroup-by-id"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ ticket_group_id: data }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response.ticket_group
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setSelectedTicketGroup: (state, action) => {
      console.log("payload", action.payload)
      state.selectedTicketGroup = action.payload
    },
    clearSelectedTicketGroup: (state) => {
      state.selectedTicketGroup = null
    },
    clearTicketGroups: (state) => {
      state.ticketGroups = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTicketGroupsForEvent.pending, (state, action) => {
      state.ticketGroupsLoading = true
    })
    builder.addCase(getAllTicketGroupsForEvent.fulfilled, (state, action) => {
      state.ticketGroupsLoading = false
      state.ticketGroups = action.payload
    })
    builder.addCase(getAllTicketGroupsForEvent.rejected, (state, action) => {
      state.ticketGroupsLoading = false
    })
    builder.addCase(getTicketGroupById.pending, (state, action) => {
      state.selectedTicketGroupLoading = true
    })
    builder.addCase(getTicketGroupById.fulfilled, (state, action) => {
      state.selectedTicketGroupLoading = false
      state.selectedTicketGroup = action.payload
    })
    builder.addCase(getTicketGroupById.rejected, (state, action) => {
      state.selectedTicketGroupLoading = false
      state.selectedTicketGroupError = true
    })
  },
})

export const {
  setSelectedTicketGroup,
  clearTicketGroups,
  clearSelectedTicketGroup,
} = ticketSlice.actions

export default ticketSlice.reducer
