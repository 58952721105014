import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useWallet } from "@solana/wallet-adapter-react"
import styles from "./Header.module.scss"
import Search from "../Search/Search"
import Button from "../Button/Button"
import { Discord, Tickets, Wallet } from "../../css/icons"
import SignInModal from "../../Authentication/SignInModal"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { setUser, signOut } from "../../reducers/userSlice"
import { getTokenAccountsForUser } from "../../helpers/getTokenAccounts"
import { Base64 } from "js-base64"
import { PublicKey, Connection, Transaction } from "@solana/web3.js" // eslint-disable-line no-unused-vars
import GoldToken from "../../Home/GoldToken"

const Header = ({ velvetHeader, velvetOnClick, showCrystal, showGold }) => {
  const { publicKey, sendTransaction } = useWallet()
  const adapter = useWallet()

  const dispatch = useDispatch()
  const history = useHistory()
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [hideGoldModal, setHideGoldModal] = useState(null)
  const [hideCrystalModal, setHideCrystalModal] = useState(null)
  const [walletCount, setWalletCount] = useState(null)

  const user = useSelector((state) => state.user.user)

  const loadWallet = async () => {
    const { decryptList } = await getTokenAccountsForUser(adapter, user)
    setWalletCount(decryptList.length)
  }

  // try to load user from localstorage... probably belongs somewhere else
  useEffect(() => {
    let u = localStorage.getItem("user")
    if (u) {
      let _user = JSON.parse(u)
      dispatch(setUser(_user))

      loadWallet()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (publicKey) {
      // user is loggged in with wallet
      document.body.style.removeProperty("overflow")
      dispatch(
        setUser({
          publicKey: publicKey.toString(),
          loginMethod: "wallet",
          // anything else we want to pull off the wallet object can go here
        })
      )
      loadWallet()
    } else {
      if (user?.loginMethod === "wallet") {
        dispatch(signOut())
      }
    }
  }, [publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const publicKeySliced = () => {
    if (!publicKey) return ""
    let str = publicKey.toString()
    if (!publicKey) return ""
    let start = str.substr(0, 4)
    let end = str.substr(str.length - 4, str.length)
    return `${start}...${end}`
  }

  const dispenser = async () => {
    // let's hit a server side dispenser
    let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/ticketdex`
    if (user.loginMethod === "phone") {
      let expressUrl = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexDispenser`
      let headers = {
        "Content-Type": "application/json",
      }
      let data = {
        url: dispenserUrl,
        diner_uuid: user.uuid,
      }
      try {
        let resp = await fetch(expressUrl, {
          method: "post",
          headers: headers,
          body: JSON.stringify(data),
        })
        console.log("resp", resp)
        resp = await resp.json()
        alert(resp.success)
      } catch (err) {
        alert("failure")
        console.log("restrictor error", err)
      }
    } else if (user.loginMethod === "wallet") {
      const res = await fetch(dispenserUrl, {
        method: "POST",
        body: JSON.stringify({
          account: user.publicKey.toString(),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!res.ok) {
        throw new TypeError("Invalid response")
      }
      const connection = new Connection(process.env.REACT_APP_RPC)
      const data = await res.json()
      const txEncoded = data.transaction
      const tx = Transaction.from(Base64.toUint8Array(txEncoded))
      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext()

      const signature = await sendTransaction(tx, connection, {
        minContextSlot,
      })
      console.log("signature", signature)
      await connection.confirmTransaction({
        blockhash,
        lastValidBlockHeight,
        signature,
      })
    }
  }

  const restrictor = async () => {
    // let's hit the restrictor hardly know her
    let data = {
      account: user.publicKey.toString(),
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/velvet/ticketdex`
    let headers = {
      "Content-Type": "application/json",
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      console.log("resp", resp)
      resp = await resp.json()
      alert(resp.valid)
    } catch (err) {
      console.log("restrictor error", err)
    }
  }

  const ref = useRef()

  useEffect(() => {
    const header = document.getElementById("header")
    const sticky = ref.current?.offsetTop
    window.onscroll = function () {
      shouldBeSticky(header, sticky)
    }
  }, [velvetHeader]) // eslint-disable-line react-hooks/exhaustive-deps

  const shouldBeSticky = (header, sticky) => {
    if (velvetHeader) {
      if (window.scrollY > sticky) {
        header.classList.add("sticky")
      } else if (window.scrollY < sticky) {
        header.classList.remove("sticky")
      }
    }
  }

  return (
    <>
      <div
        className={classNames(
          velvetHeader ? styles.velvetHeader : styles.blackHeader
        )}
        id="header"
        ref={ref}
      >
        <div className={styles.headerInner}>
          <img
            className={styles.headerLogo}
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
          />
          {!velvetHeader && <Search />}
          <div className={styles.headerRight}>
            <div
              onClick={() => {
                if (velvetHeader) {
                  velvetOnClick()
                } else {
                  history.push("/events")
                }
              }}
              className={classNames(
                styles.headerLink,
                window.location.pathname === "/events" && styles.selected
              )}
            >
              Upcoming Events
            </div>
            {velvetHeader && (
              <a
                href="http://sell.xp.xyz"
                className={classNames(
                  styles.headerLink,
                  window.location.pathname === "/events" && styles.selected
                )}
              >
                Sell Your Tickets
              </a>
            )}

            <a
              href="https://discord.gg/GYrHjHjybg"
              className={classNames(styles.headerLink, styles.discord)}
              target="_blank"
              rel="noreferrer"
            >
              Join Discord <Discord />
            </a>

            {user?.publicKey && false && (
              <Button onClick={restrictor}>Restrictor</Button>
            )}

            {user?.publicKey && false && (
              <Button onClick={dispenser}>Dispenser</Button>
            )}

            {user?.publicKey ? (
              <div className={styles.loggedInButtonContainer}>
                <Link className={styles.ticketCount} to={"/holder/NFTs"}>
                  <Tickets className={styles.tickets} />{" "}
                  {walletCount === null ? (
                    <img
                      alt="wallet loading"
                      className={styles.walletLoading}
                      src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/loading-gif.gif"
                    />
                  ) : (
                    walletCount
                  )}
                </Link>
                {user.loginMethod === "wallet" ? (
                  <WalletMultiButton>
                    <Wallet className={styles.wallet} />
                    {window.innerWidth > 768 && publicKeySliced()}
                  </WalletMultiButton>
                ) : (
                  <div className={styles.phoneButton}>{user?.phone}</div>
                )}
              </div>
            ) : (
              <Button
                className={styles.signInButton}
                onClick={() => {
                  // document.body.style.overflow = "hidden"
                  setShowSigninModal(true)
                }}
                variant="gray"
              >
                {user?.uuid ? "phone logged in " : "Connect Wallet / Sign In"}
              </Button>
            )}
          </div>
        </div>
      </div>
      {showSigninModal && (
        <SignInModal onClose={() => setShowSigninModal(false)} />
      )}
      {showGold && hideGoldModal !== true && (
        <GoldToken color="gold" onClose={() => setHideGoldModal(true)} />
      )}
      {showCrystal && hideCrystalModal !== true && (
        <GoldToken color="crystal" onClose={() => setHideCrystalModal(true)} />
      )}
    </>
  )
}

export default Header
