import classNames from "classnames"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import styles from "./Layout.module.scss"

const Layout = ({ children, className, showCrystal, showGold }) => (
  <div className={classNames(styles.pageContainer, className)}>
    <img
      alt="background"
      className={styles.bgImage}
      id="backgroundImage"
      src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp_homepage_12_bg-1-min.webp"
    />
    <Header
      showCrystal={showCrystal}
      showGold={showGold}
      velvetHeader={false}
    />
    <div className={styles.contentContainer}>{children}</div>
    <Footer />
  </div>
)

export default Layout
