import classNames from "classnames"
import { AngleRight, USDC } from "../../css/icons"
import styles from "./EventCard.module.scss"
import { useHistory } from "react-router-dom"
import DateTag from "../DateTag/DateTag"

const EventCard = ({ className, event, velvetOnClick }) => {
  const history = useHistory()

  if (!event) {
    return <div className={classNames(styles.card, className, styles.loader)} />
  }

  return (
    <div
      className={classNames(styles.card, className)}
      onClick={() => {
        if (velvetOnClick) {
          velvetOnClick()
        } else {
          history.push(`/event/${event.event_id}`)
        }
      }}
    >
      <div className={styles.cardTop}>
        <img
          className={styles.cardImg}
          src={
            event?.image
              ? event.image
              : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
          }
          alt={event?.name}
        />
        <DateTag event={event} className={styles.dateTag} />
      </div>
      <div className={styles.cardBottom}>
        <div className={styles.titleContainer}>
          <h3>{event?.short_title || event?.title}</h3>
        </div>
        <p>
          <span>{event?.venue_name}</span>•<span>{event?.venue_city}</span>•
          <span>{event?.venue_state}</span>
        </p>
        <div className={styles.priceContainer}>
          <p className={styles.priceDescription}>
            From <USDC />
            <span>${event?.min_ticket_price} USDC</span>
            <AngleRight className={styles.angle} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default EventCard
