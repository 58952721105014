import { Link } from "react-router-dom"
import { Button } from "../components"
import { ArrowRight, Guitar, Tickets, USDC } from "../css/icons"
import styles from "./NoNfts.module.scss"

const NoNFTs = () => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>
      <Tickets />
    </div>
    <p>You have no upcoming events</p>
    <div className={styles.new}>
      <h2>New to Ticketdex?</h2>
      <p>Let's get you started</p>
      <div className={styles.tileContainer}>
        <div className={styles.tile}>
          <div className={styles.tileTop}>
            <div className={styles.iconContainer}>
              <USDC />
            </div>
            <div>
              <h3>Fuel Your Journey with USDC</h3>
              <p>
                Load up your wallet with USDC to unlock ticket purchases and
                attend the hottest events.
              </p>
            </div>
          </div>
          <Button variant="blue" fullWidth>
            Purchase USDC
          </Button>
        </div>
        <ArrowRight className={styles.arrowRight} />
        <div className={styles.tile}>
          <div className={styles.tileTop}>
            <div className={styles.iconContainer}>
              <Guitar />
            </div>
            <div>
              <h3>Discover Your Next Concert</h3>
              <p>
                Explore upcoming concerts, dive into the XP journey, and
                unforgettable experiences.
              </p>
            </div>
          </div>
          <Link to="/events">
            <Button variant="blue" fullWidth>
              Explore More
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default NoNFTs
