import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Layout, NFTListItem } from "../components"
import { Past, Ticket, Tickets } from "../css/icons"
import { PublicKey, Connection } from "@solana/web3.js"
import NoNFTs from "./NoNFTs"
import styles from "./NFTs.module.scss"
import { getTokenAccountsForUser } from "../helpers/getTokenAccounts"
import {
  setConfig,
  PDA,
  Operator,
  WalletAdapterIdentity,
  KeypairIdentity,
  Currency,
  TokenAccount,
  MerkelTreeAuthAccount,
  CNFT,
} from "@captainxyz/solana-core"

require("@solana/wallet-adapter-react-ui/styles.css")

const HolderNFTs = () => {
  const [loading, setLoading] = useState(false)
  const [canDecryptList, setCanDecryptList] = useState([])
  const [canDecryptListPast, setCanDecryptListPast] = useState([])
  const [hasNoNfts, setHasNoNFTs] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState("Upcoming")
  const [showCrystal, setShowCrystal] = useState(false)
  const [showGold, setShowGold] = useState(false)
  const [showTileModal, setShowTileModal] = useState(false)
  const adapter = useWallet()
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    if (adapter.publicKey) {
      getTokenAccounts()
      checkForTiles()
    }

    if (user?.publicKey) {
      getTokenAccounts()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter.publicKey) {
      getTokenAccounts()
    }
  }, [adapter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.publicKey) {
      getTokenAccounts()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForTiles = async () => {
    if (!adapter) {
      console.log("Adapter not set. Not checking for tiles")
      return
    }

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity)
    const cnfts = await CNFT.find(operator)

    for (const cnft of cnfts) {
      if (cnft.json.name === "XP Crystal Chip") {
        setShowCrystal(true)
      }
      if (cnft.json.name === "XP Gold Chip") {
        setShowGold(true)
      }
    }
  }

  const getTokenAccounts = async () => {
    setLoading(true)
    await getTokenAccountsForUser(adapter, user).then(
      ({ decryptList, decryptListPast }) => {
        setCanDecryptList(decryptList)
        setCanDecryptListPast(decryptListPast)
        setLoading(false)
        if (!decryptList.length && !decryptListPast.length) {
          setHasNoNFTs(true)
        }
      }
    )
  }

  if (loading) {
    return (
      <Layout
        showCrystal={showCrystal && showTileModal}
        showGold={showGold && showTileModal}
      >
        <div className={styles.loadingContainer}>
          <img
            src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
            className={styles.spinner}
            alt="spinner"
          />
          <div style={{ marginTop: "32px" }} className={styles.loadingSubtext}>
            Scanning your wallet for Tamperproof NFTs
          </div>
          <div style={{ marginTop: "8px" }} className={styles.loadingSubtext}>
            If you have a large NFT collection, this will take a moment
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <>
      <Layout
        showCrystal={showCrystal && showTileModal}
        showGold={showGold && showTileModal}
      >
        <div className={styles.pageContainer}>
          <div className={styles.header}>
            <h1>My Tickets</h1>
            <div className={styles.buttonHeaderRow}>
              <div className={styles.filterButtonContainer}>
                <Button
                  variant="gray"
                  outline={selectedFilter === "Upcoming"}
                  onClick={() => setSelectedFilter("Upcoming")}
                >
                  <Ticket />
                  Upcoming Events{" "}
                  {canDecryptList.length > 0 && `(${canDecryptList.length})`}
                </Button>
                <Button
                  variant="gray"
                  outline={selectedFilter === "Past"}
                  onClick={() => setSelectedFilter("Past")}
                >
                  <Past />
                  Past Events
                </Button>
              </div>
              {(showGold || showCrystal) && (
                <Button
                  variant="blue"
                  onClick={() => {
                    if (showTileModal) {
                      alert("Please refresh the page to cash out your chip.")
                    } else {
                      setShowTileModal(true)
                    }
                  }}
                >
                  Cash Out XP Chip
                </Button>
              )}
            </div>
          </div>
          <div className={styles.nftContainer}>
            {hasNoNfts ? (
              <NoNFTs />
            ) : (
              <div>
                {selectedFilter === "Upcoming" ? (
                  <>
                    {canDecryptList.length > 0 ? (
                      <>
                        {canDecryptList.map((nft, i) => (
                          <NFTListItem
                            key={nft.mint.address.toString()}
                            nft={nft}
                          />
                        ))}
                      </>
                    ) : (
                      <div className={styles.noUpcoming}>
                        <div className={styles.iconContainer}>
                          <Tickets />
                        </div>
                        <p>You have no upcoming events</p>
                        <Link to="/events">
                          <Button variant="blue">Explore Concerts</Button>
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {canDecryptListPast.map((nft, i) => (
                      <NFTListItem
                        key={nft.mint.address.toString()}
                        nft={nft}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HolderNFTs
