import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useParams } from "react-router-dom"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"

// DO NOT DELETE THESE OR THE WHOLE THING WON'T WORK FOR SOME REASON
import {
  isTamperproofHolder, // eslint-disable-line no-unused-vars
  isTamperproofOracle, // eslint-disable-line no-unused-vars
} from "@captainxyz/tamperproof"

import { PublicKey } from "@solana/web3.js"
import classNames from "classnames"
import { Button, Layout, Tag } from "../components"
import Loading from "../Loading/Loading"
import ErrorModal from "./ErrorModal"
import UnsealModal from "./UnsealModal"
import UnsealedModal from "./UnsealedModal"
import DecryptedModal from "./DecryptedModal"
import SellTicketsModal from "./SellTicketsModal"
import { useSelector } from "react-redux"

import styles from "./NFT.module.scss"
import {
  AngleRight,
  CheckCircle,
  CloseCircle,
  Info,
  Ticket,
  Tickets,
  USDC,
  Venue,
  Wallet,
} from "../css/icons"

import postToSlack from "../postToSlack"
import * as Sentry from "@sentry/browser"
import { formatDate } from "../helpers/formatDate"

const NFT = (props) => {
  const [secret, setSecret] = useState(null)
  const [email, setEmail] = useState(null)
  const [token, setToken] = useState(false)
  // const [showMore, setShowMore] = useState(false)
  const [sealed, setSealed] = useState(null)
  const [unsealed, setUnsealed] = useState(null)
  const [showUnsealModal, setShowUnsealModal] = useState(false)
  const [showUnsealingModal, setShowUnsealingModal] = useState(false)
  const [showUnsealedModal, setShowUnsealedModal] = useState(false)
  const [showDecryptedModal, setShowDecryptedModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showComingSoonModal, setShowComingSoonModal] = useState(false)
  const [unsealSuccess, setUnsealSuccess] = useState(false)
  const user = useSelector((state) => state.user.user)
  const { mint } = useParams()

  const adapter = useWallet()

  useEffect(() => {
    if (user?.publicKey) {
      //getTokenAccounts()
      getToken()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  // todo: put this back in
  // useEffect(() => {
  //   if (!adapter.publicKey) history.replace("/holder/landing")
  // }, [adapter.publicKey])

  useEffect(() => {
    if (unsealSuccess) {
      setShowUnsealingModal(true)

      setTimeout(() => {
        setShowUnsealingModal(false)
      }, 4000)
    }
  }, [unsealSuccess])

  useEffect(() => {
    if (adapter.signMessage) {
      if (adapter.publicKey) {
        postToSlack(`${adapter.publicKey.toString()} is viewing NFT ${mint}`)
      }
    }
  }, [adapter.signMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  const getToken = async () => {
    setConfig("mainnet-beta", {
      rpcEndpoint:process.env.REACT_APP_RPC
    })
    let operator
    if (user.loginMethod === "wallet") {
      const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
      operator = new Operator("mainnet-beta", walletAdapterIdentity)
    } else {
      operator = new Operator("mainnet-beta")
    }

    let pubKey = new PublicKey(user.publicKey)
    let tokenAddress = PDA.token(new PublicKey(mint), pubKey)
    let token = await TokenAccount.init(operator, tokenAddress)

    if (token.canRequestUnsealing) {
      // eslint-disable-next-line eqeqeq
      if (token.mint.address.toString() == mint) {
        setToken(token)
        setSealed(true)
        return
      }
    }
    if (token.canDecrypt) {
      // eslint-disable-next-line eqeqeq
      if (token.mint.address.toString() == mint) {
        setToken(token)
        setUnsealed(true)
        return
      }
    }
  }

  const unsealWithAdapter = async () => {
    // if we somehow got here with an already unsealed one
    if (token.canDecrypt) {
      setShowUnsealingModal(false)
      setShowUnsealedModal(true)
      return
    }

    let a
    try {
      a = await token?.requestUnsealing()
    } catch (err) {
      console.log("request unseal error", err)
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Bug with unsealing")
      })
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofRequestReveal`
    console.log("process", process.env)
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: adapter.publicKey.toString(),
      sig: a.signature,
      mint: token.mint.address.toString(),
      oracle_id: token.metadata._json.oracle_id,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      console.log("process", process.env)
      resp = await resp.json()
      if (resp.success) {
        setUnsealSuccess(true)
        setShowUnsealingModal(false)
        // setShowUnsealedModal(true)
        postToSlack(
          `${adapter.publicKey.toString()} succesfully unsealed for ${mint}`
        )
        // todo: make these the same variable, because having two is gross
        setUnsealed(true)
        setSealed(false)
      } else {
        postToSlack(
          `${adapter.publicKey.toString()} captured unsealed error for ${mint}`
        )
        setShowUnsealingModal(false)
        setShowErrorModal(true)
      }
    } catch (err) {
      console.log(err)
      postToSlack(
        `${adapter.publicKey.toString()} unkown unsealed error for ${mint}`
      )
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const unsealServerSide = async () => {
    let data = {
      diner_uuid: user.uuid,
      mint: mint,
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexRequestUnseal`

    let headers = {
      "Content-Type": "application/json",
    }

    let resp
    try {
      resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
    } catch (err) {
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    if (resp.success) {
      setUnsealSuccess(true)
      setShowUnsealingModal(false)
      postToSlack(`${user.publicKey} succesfully unsealed for ${mint}`)
      setUnsealed(true)
      setSealed(false)
    } else {
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const unseal = async () => {
    setShowErrorModal(false)
    setShowUnsealModal(false)
    setShowUnsealingModal(true)
    postToSlack(`${user.publicKey} is requesting unseal for ${mint}`)
    if (user.loginMethod === "wallet") unsealWithAdapter()
    else unsealServerSide()
  }

  const decryptFromModal = async () => {
    postToSlack(
      `${adapter.publicKey.toString()} decrypted from modal for ${mint}`
    )
    try {
      let secret = await token.decrypt()
      setSecret(secret)
      setShowDecryptedModal(true)
      setShowUnsealedModal(false)
    } catch (err) {
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        Sentry.configureScope((scope) => {
          Sentry.captureMessage("Bug with decrypting from modal")
        })
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
    }
  }

  const decrypt = async () => {
    let secret
    if (user.loginMethod === "wallet") {
      try {
        secret = await token.decrypt()
      } catch (err) {
        Sentry.configureScope((scope) => {
          Sentry.captureMessage("Bug with decrypting from nft page")
        })
        Sentry.captureException(err)
        if (
          err.message &&
          err.message.indexOf("has not been authorized") > -1
        ) {
          alert("There may be an issue with Phantom. Please refresh the page.")
        }
        console.log("error", err)
        alert("error")
        return
      }
      setSecret(secret)
      postToSlack(
        `${adapter.publicKey.toString()} decrypted from nft page for ${mint}`
      )
    } else {
      let data = {
        diner_uuid: user.uuid,
        mint: mint,
      }
      let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexDecrypt`

      let headers = {
        "Content-Type": "application/json",
      }

      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      console.log("Resp is", resp)
      alert(resp.secret)
      setSecret(resp.secret)
    }
  }

  // const renderDescription = () => {
  //   let description = token.metadata._json.description
  //   if (!description) return null
  //   let lines = description.split("\n")
  //   return (
  //     <>
  //       {lines.map((line, index) => {
  //         if (index < 2) {
  //           return (
  //             <>
  //               {line && (
  //                 <div
  //                   style={{ marginBottom: "12px" }}
  //                   className={styles.subtextLight}
  //                 >
  //                   {line}
  //                 </div>
  //               )}
  //             </>
  //           )
  //         } else {
  //           if (showMore) {
  //             return (
  //               <>
  //                 {line && (
  //                   <div
  //                     style={{ marginBottom: "12px" }}
  //                     className={styles.subtextLight}
  //                   >
  //                     {line}
  //                   </div>
  //                 )}
  //               </>
  //             )
  //           }
  //         }
  //       })}

  //       {!showMore && (
  //         <span onClick={() => setShowMore(true)} className="show-more">
  //           {" "}
  //           Show More...{" "}
  //         </span>
  //       )}
  //     </>
  //   )
  // }

  const submitCode = async (code, email) => {
    let url = `${process.env.REACT_APP_HNGR_API}/ticketdex/claim-code`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = { code: code, email: email }
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  return (
    <>
      {showDecryptedModal && (
        <DecryptedModal
          secret={secret}
          token={token}
          close={() => {
            setSealed(false)
            setUnsealed(true)
            setShowDecryptedModal(false)
          }}
          onSubmit={(code, email) => {
            setEmail(email)
            setShowDecryptedModal(false)
            submitCode(code, email)
          }}
        />
      )}
      {showUnsealModal && (
        <UnsealModal
          close={() => {
            document.body.style.overflow = "auto"
            setShowUnsealModal(false)
          }}
          unseal={() => unseal()}
        />
      )}
      {showUnsealedModal && (
        <UnsealedModal
          close={() => setShowUnsealedModal(false)}
          token={token}
          decrypt={() => decryptFromModal()}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          token={token}
          unseal={() => unseal()}
          close={() => setShowErrorModal(false)}
        />
      )}
      {showComingSoonModal && (
        <SellTicketsModal
          close={() => {
            document.body.style.overflow = "auto"
            setShowComingSoonModal(false)
          }}
          unseal={() => unseal()}
        />
      )}

      <Layout>
        {showUnsealingModal ? (
          <>
            {unsealSuccess ? (
              <div className={styles.unsealLoading}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/unlock.gif"
                  alt="Unsealed"
                />
              </div>
            ) : (
              <div className={styles.unsealLoading}>
                <h1>Activating your ticket...</h1>
                <p>This may take up to 60 seconds</p>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/blink_182_unsealing_v2_360.gif"
                  alt="Unsealing"
                />
              </div>
            )}
          </>
        ) : (
          <>
            {token && (sealed || unsealed) ? (
              <div className={styles.contentContainer}>
                <div className={styles.contentLeft}>
                  <div className={styles.imageContainer}>
                    <img alt="cube" src={token.metadata._json.image} />
                  </div>

                  <div
                    className={classNames(
                      styles.container,
                      styles.venueInfoContainer
                    )}
                  >
                    <div>
                      <div className={styles.date}>
                        {
                          token.metadata._json.attributes?.find(
                            (attr) => attr.trait_type === "Date"
                          )?.value
                        } at&nbsp; 
                        {
                          token.metadata._json.attributes?.find(
                            (attr) => attr.trait_type === "Showtime"
                          )?.value
                        }
                      </div>
                      <div className={styles.title}>
                        {
                          token.metadata._json.attributes?.find(
                            (attr) => attr.trait_type === "Artist"
                          )?.value
                        }
                      </div>
                      <div className={styles.venue}>
                        <Venue />
                        {
                          token.metadata._json.attributes?.find(
                            (attr) => attr.trait_type === "Venue Name"
                          )?.value
                        }
                        ,{" "}
                        {
                          token.metadata._json.attributes?.find(
                            (attr) => attr.trait_type === "City"
                          )?.value
                        }
                        ,{" "}
                        {
                          token.metadata._json.attributes?.find(
                            (attr) => attr.trait_type === "Venue State"
                          )?.value
                        }
                      </div>
                    </div>
                    <Button compact variant="gray" className={styles.info}>
                      <Info />
                    </Button>
                  </div>
                </div>
                <div className={styles.contentRight}>
                  <div className={styles.metadataContainer}>
                    <div className={styles.metadataHeader}>
                      <h1>My Tickets</h1>
                    </div>

                    <div className={styles.metadataItem}>
                      <img
                        src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/cubeplaceholder.png"
                        alt="Tickets"
                        className={styles.ticketImage}
                      />
                      <div className={styles.metadataInner}>
                        <div>
                          <div className={styles.subtext}>
                            {
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Section"
                              )?.value
                            }
                            , Row{" "}
                            {
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Row"
                              )?.value
                            }
                            , Seat{" "}
                            {
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Seat"
                              )?.value
                            }
                          </div>
                          <div className={styles.subtextLight}>
                            <Ticket />
                            {
                              token.metadata._json.attributes?.find(
                                (attr) => attr.trait_type === "Ticket Quantity"
                              )?.value
                            }{" "}
                            Tickets
                          </div>
                        </div>
                        <Button
                          variant="gray"
                          compact
                          className={styles.smallButton}
                        >
                          Seatmap
                        </Button>
                      </div>
                    </div>
                    <div className={styles.metadataRow}>
                      <div
                        className={classNames(
                          styles.metadataItem,
                          styles.metadataItemHalf
                        )}
                      >
                        <div className={styles.metadataContent}>
                          <div className={styles.metadataContentInner}>
                            <div className={styles.iconContainer}>
                              <Tickets />
                            </div>
                            <div>
                              <div className={styles.subtextLight}>
                                Issued By
                              </div>
                              <div className={styles.subtext}>Gold Coast</div>
                            </div>
                          </div>
                          <AngleRight className={styles.angle} />
                        </div>
                      </div>
                      <div
                        className={classNames(
                          styles.metadataItem,
                          styles.metadataItemHalf
                        )}
                      >
                        <div className={styles.metadataContent}>
                          <div className={styles.metadataContentInner}>
                            <div className={styles.iconContainer}>
                              <Wallet />
                            </div>
                            <div>
                              <div className={styles.subtextLight}>
                                Owned By
                              </div>
                              <div className={styles.subtext}>Me</div>
                            </div>
                          </div>
                        </div>
                        <AngleRight className={styles.angle} />
                      </div>
                    </div>
                  </div>

                  <div className={styles.ticketStepContainer}>
                    <div className={styles.actionHeader}>
                      <h1>Ticket Status</h1>
                      <Tag
                        content={
                          sealed
                            ? "Sealed"
                            : secret
                            ? "Claimed"
                            : "Ready to Claim"
                        }
                        variant={
                          sealed
                            ? "sealed"
                            : secret
                            ? "claimed"
                            : "readyToClaim"
                        }
                      />
                    </div>
                    <div
                      className={classNames(
                        styles.metadataItem,
                        styles.stepContainer
                      )}
                    >
                      <div className={styles.step}>
                        <div>
                          <div
                            className={classNames(
                              styles.stepHeader,
                              sealed && styles.activeStep,
                              unsealed && styles.completedStep
                            )}
                          >
                            {unsealed ? "Activated Ticket" : "Activate Ticket"}
                            {unsealed && <CheckCircle />}
                          </div>
                          {!unsealed && (
                            <div
                              className={classNames(
                                styles.stepDescription,
                                styles.activeDescription
                              )}
                            >
                              {/* TODO: once unsealing is working, show time of unseal here */}
                              Activate your ticket on the public blockchain to
                              break the seal and confirm your attendance to the
                              show.
                            </div>
                          )}
                          {unsealed && (
                            <div
                              className={classNames(
                                styles.stepDescription,
                                styles.activeDescription
                              )}
                            >
                              by Me
                            </div>
                          )}
                        </div>
                      </div>
                      {!unsealed && (
                        <>
                          <div className={styles.buttonContainer}>
                            <Button
                              variant="gray"
                              onClick={() => {
                                // document.body.style.overflow = "hidden"
                                setShowComingSoonModal(true)
                              }}
                            >
                              List for Sale
                            </Button>
                            <Button
                              variant="blue"
                              onClick={() => {
                                // document.body.style.overflow = "hidden"
                                setShowUnsealModal(true)
                              }}
                            >
                              Activate My Ticket
                            </Button>
                          </div>
                          <p className={styles.unsealWarning}>
                            ⚠️ Activating unlocks access to the contents inside.
                          </p>
                        </>
                      )}
                    </div>
                    <div
                      className={classNames(
                        styles.metadataItem,
                        styles.disabledStep
                      )}
                    >
                      <div className={styles.step}>
                        <div>
                          <div
                            className={classNames(
                              styles.stepHeader,
                              unsealed && !secret && styles.activeStep,
                              unsealed && secret && styles.completedStep
                            )}
                          >
                            Allow Transfers
                            {unsealed && secret && <CheckCircle />}
                          </div>
                          {unsealed && secret && (
                            <div
                              className={classNames(
                                styles.stepDescription,
                                styles.activeDescription
                              )}
                            >
                              by Me
                            </div>
                          )}
                          {unsealed && !secret && (
                            <>
                              <div
                                className={classNames(
                                  styles.stepDescription,
                                  unsealed &&
                                    !secret &&
                                    styles.activeDescription,
                                  unsealed &&
                                    secret &&
                                    styles.completedDescription
                                )}
                              >
                                Unlock transfers with a wallet signature. A
                                signature is required every time for the highest
                                level of security.
                              </div>
                              <Button
                                variant="blue"
                                className={styles.button}
                                fullWidth
                                onClick={() => decrypt()}
                              >
                                Allow Transfers
                              </Button>
                              <p className={styles.unsealWarning}>
                                ⚠️ Avoid selling activated tickets as the broken
                                seal has compromised authenticity
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        styles.metadataItem,
                        styles.disabledStep
                      )}
                    >
                      <div className={styles.step}>
                        <div>
                          <div
                            className={classNames(
                              styles.stepHeader,
                              unsealed && secret && styles.activeStep
                            )}
                          >
                            Claim Ticket
                          </div>

                          <div
                            className={classNames(
                              styles.stepDescription,
                              unsealed && sealed && styles.activeDescription
                            )}
                          >
                            Claim your event ticket and send to your mobile
                            device for seamless access into the show.
                          </div>
                          {unsealed && secret && (
                            <Button
                              variant="blue"
                              fullWidth
                              onClick={() => setShowDecryptedModal(true)}
                              className={styles.button}
                            >
                              Claim My Ticket
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    {email && (
                      <>
                        <div className={styles.claimSuccess} />
                        <div className={styles.claimTextBg} />
                        <div className={styles.claim}>
                          <h1>You're going to the show!</h1>
                          <p>Tickets sent to {email}</p>
                        </div>
                        <CloseCircle
                          className={styles.close}
                          onClick={() => setEmail(null)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Layout>
    </>
  )
}

export default NFT
