import { AccountInfo, PublicKey } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";
import * as MPLAuctionHouse from "@metaplex-foundation/mpl-auction-house";
import {
  Operator,
  TransactionResult,
  Amount,
  BaseAccount,
  TokenAccount,
  MintAccount,
  SolanaAccount,
} from "@captainxyz/solana-core";

import { Transactions } from "./transactions";
import { PDA } from "./pda";

export class AuctionHouseAccount extends BaseAccount {
  public readonly treasuryMint: MintAccount | null;
  public readonly feeAccount: SolanaAccount;

  private _account: MPLAuctionHouse.AuctionHouse;

  public get authority(): PublicKey {
    return this._account.authority;
  }

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
    account: MPLAuctionHouse.AuctionHouse;
    treasuryMint: MintAccount | null;
    feeAccount: SolanaAccount;
  }) {
    super(params);
    this._account = params.account;
    this.treasuryMint = params.treasuryMint;
    this.feeAccount = params.feeAccount;
  }

  public static async init(
    operator: Operator,
    address: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null
  ): Promise<AuctionHouseAccount> {
    const existent = await this._checkExistent<AuctionHouseAccount>(
      operator,
      address,
      accountInfo
    );
    if (existent) return existent;

    accountInfo ??= await operator.connection.getAccountInfo(address);
    if (!accountInfo) throw new Error(`Account not found ${address}`);
    const [account] = MPLAuctionHouse.AuctionHouse.fromAccountInfo(accountInfo);

    const treasuryMint = account.treasuryMint.equals(SPLToken.NATIVE_MINT)
      ? null
      : await MintAccount.init(operator, account.treasuryMint);
    const feeAccount = await SolanaAccount.init(
      operator,
      account.auctionHouseFeeAccount
    );

    const result = new this({
      operator,
      address,
      lamports: accountInfo.lamports,
      account,
      treasuryMint,
      feeAccount,
    });
    return await result._init();
  }

  public static async create(
    operator: Operator,
    treasuryMint?: PublicKey,
    owner?: PublicKey
  ): Promise<[AuctionHouseAccount, TransactionResult]> {
    if (!operator.identity) throw new Error("Operator is in read-only mode");

    owner ??= operator.identity.publicKey;
    const address = PDA.auctionHouse(owner, treasuryMint);

    const result = await operator.execute(
      Transactions.createAuctionHouse({
        payer: operator.identity.publicKey,
        owner,
        address,
        treasuryMint,
      })
    );
    const account = await this.init(
      operator,
      address,
      result.accounts.get(address.toString())
    );
    return [account, result];
  }

  public async listNFTForSale(
    nft: TokenAccount,
    price: Amount
  ): Promise<TransactionResult> {
    if (!nft.canSend) {
      throw new Error(`Account ${nft} does not belong to the operator`);
    }

    return await this.operator.execute(
      Transactions.listNFTForSale({
        seller: nft.owner,
        auctionHouse: {
          address: this.address,
          authority: this.authority,
          treasuryMint: this.treasuryMint?.address,
        },
        nft: {
          address: nft.address,
          mint: nft.mint.address,
          metadata: nft.metadata.address,
        },
        price,
      })
    );
  }

  public async buyNFT(
    nft: TokenAccount,
    price: Amount
  ): Promise<[TokenAccount, TransactionResult]> {
    if (!this.operator.identity) {
      throw new Error("Operator is in read-only mode");
    }

    const result = await this.operator.execute(
      Transactions.buyNFT({
        buyer: this.operator.identity.publicKey,
        seller: nft.owner,
        auctionHouse: {
          address: this.address,
          authority: this.authority,
          treasuryMint: this.treasuryMint?.address,
        },
        nft: {
          address: nft.address,
          mint: nft.mint.address,
          metadata: nft.metadata.address,
          creators: nft.metadata.creators?.map((creator) => creator.address),
        },
        price,
      })
    );
    const address = PDA.token(
      nft.mint.address,
      this.operator.identity.publicKey
    );
    const account = await TokenAccount.init(
      this.operator,
      address,
      result.accounts.get(address.toString())
    );
    return [account, result];
  }
}
