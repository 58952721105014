import { useHistory } from "react-router-dom"
import { formatDate } from "../helpers/formatDate"
import { Ticket, Venue } from "../css/icons"
import { Button, Tag } from "../components"
import styles from "./NFTListItem.module.scss"

const NFTListItem = ({ nft }) => {
  const history = useHistory()
  const sealed = nft.metadata._json.secret.state === "sealed"
  const revealed = true // todo: get this value from metadata

  const dateAttr = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Date"
  )?.value

  const timeAttr = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Showtime"
  )?.value

  const date = dateAttr + " AT " + timeAttr

  const venueName = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Venue Name"
  )?.value

  const venueCity = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "City"
  )?.value

  const venueState = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Venue State"
  )?.value

  const ticketQuantity = nft.metadata._json.attributes?.find(
    (attr) => attr.trait_type === "Ticket Quantity"
  )?.value

  return (
    <div
      className={styles.nftListItem}
      onClick={() => {
        let mint = nft.mint.address.toString()
        history.push(`/holder/NFT/${mint}`)
      }}
    >
      <div className={styles.nftInfo}>
        <img
          alt="img"
          className={styles.nftImage}
          src={nft.metadata._json.image}
        />
        <div>
          <div className={styles.nftDate}>{date}</div>
          <div className={styles.nftTitle}>
            {
              nft.metadata._json.attributes?.find(
                (attr) => attr.trait_type === "Artist"
              )?.value
            }
          </div>
          <div className={styles.nftVenue}>
            <Venue />
            {venueName}, {venueCity}, {venueState}
          </div>
        </div>
      </div>
      <div className={styles.nftStatus}>
        <Tag
          content={sealed ? "Sealed" : revealed ? "Claimed" : "Ready to Claim"}
          variant={sealed ? "sealed" : revealed ? "claimed" : "unsealed"}
        />

        <Button variant="gray" className={styles.ticketQuantity}>
          <Ticket />
          {ticketQuantity} {ticketQuantity > 1 ? "Tickets" : "Ticket"}
        </Button>
      </div>
    </div>
  )
}
export default NFTListItem
