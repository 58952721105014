export const Past = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 9.48583C16.5076 13.6181 13.1377 16.9971 9.00544 17C7.22071 17.0013 5.58136 16.3791 4.29308 15.3394C3.95809 15.069 3.93305 14.567 4.23747 14.2626L4.5782 13.9218C4.83856 13.6615 5.2542 13.633 5.54265 13.8618C6.49179 14.615 7.69297 15.0645 8.99999 15.0645C12.0757 15.0645 14.5645 12.5753 14.5645 9.50001C14.5645 6.42425 12.0753 3.93549 8.99999 3.93549C7.52376 3.93549 6.18299 4.50915 5.18746 5.44553L6.72235 6.98043C7.02719 7.28527 6.8113 7.80646 6.38023 7.80646H1.98387C1.71662 7.80646 1.5 7.58984 1.5 7.32259V2.92622C1.5 2.49515 2.02119 2.27925 2.32603 2.58406L3.81913 4.07717C5.16559 2.7904 6.99048 2 8.99999 2C13.1374 2 16.4923 5.35021 16.5 9.48583ZM11.0289 11.8684L11.3259 11.4865C11.572 11.17 11.515 10.714 11.1986 10.4679L9.96773 9.51057V6.35485C9.96773 5.95399 9.64278 5.62904 9.24193 5.62904H8.75806C8.3572 5.62904 8.03225 5.95399 8.03225 6.35485V10.4572L10.0103 11.9957C10.3268 12.2418 10.7828 12.1848 11.0289 11.8684Z"
      fill="#FAFAFA"
    />
  </svg>
)
