import EventCard from "../EventCard/EventCard"
import "./CardCarousel.scss"
import React from "react"
import classNames from "classnames"

function sideScroll(element, direction, speed, distance, step) {
  let scrollAmount = 0
  var slideTimer = setInterval(() => {
    if (direction === "left") {
      element.scrollLeft -= step
    } else {
      element.scrollLeft += step
    }
    scrollAmount += step
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer)
    }
  }, speed)
}

// adapted from https://codepen.io/tdextrous/pen/ROBvyz?editors=0011

class CardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disableScroll: false,
      scrollWidth: 0,
      scrollPos: 1,
      clonesWidth: 0,
    }
    this.scrollContainerRef = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
    this.scrollNext = this.scrollNext.bind(this)
    this.scrollPrev = this.scrollPrev.bind(this)
    this.setScroll = this.setScroll.bind(this)
    this.getClonesWidth = this.getClonesWidth.bind(this)
    this.reCalc = this.reCalc.bind(this)
  }

  reCalc() {
    let scrollPos = this.state.scrollPos
    let scrollWidth = this.scrollContainerRef.current.clientWidth
    let clonesWidth = this.getClonesWidth()

    if (scrollPos <= 0) {
      scrollPos = 1
    }
    this.setState({
      scrollPos: scrollPos,
      scrollWidth: scrollWidth,
      clonesWidth: clonesWidth,
    })
  }

  handleScroll(e) {
    const container = e.currentTarget
    const scrollWidth = container.scrollWidth
    const clonesWidth = this.getClonesWidth()
    let scrollPos = container.scrollLeft
    let scrollPosAdd
    container.clientWidth > clonesWidth
      ? (scrollPosAdd = container.clientWidth)
      : (scrollPosAdd = clonesWidth)

    if (!this.state.disableScroll) {
      if (scrollPos + scrollPosAdd >= scrollWidth) {
        this.setScroll(
          // The math floor value helps smooth out the scroll jump,
          // I don't know why that particular value works, but it does
          // Same goes for the other setScroll call below
          container,
          1 + Math.floor(scrollPosAdd / 12.09)
        )
        this.setState({
          disableScroll: true,
        })
      } else if (scrollPos <= 0) {
        this.setScroll(
          container,
          scrollWidth - clonesWidth - Math.floor(scrollPosAdd / 12.09)
        )
        this.setState({
          disableScroll: true,
        })
      }
    }

    this.setState({
      scrollWidth: container.scrollWidth,
      scrollPos: container.scrollLeft,
    })
  }

  getClonesWidth() {
    const clones = document.getElementsByClassName(
      `is-clone-${this.props.carouselId}`
    )
    let clonesWidth = 0
    for (let i = 0; i < clones.length; i++) {
      clonesWidth = clonesWidth + clones[i].clientWidth
    }

    return clonesWidth
  }

  setScroll(element, pos) {
    element.scrollLeft = pos
    this.setState({
      scrollPos: element.scrollLeft,
    })
  }

  scrollNext(e) {
    const container = e.currentTarget.previousSibling
    sideScroll(container, "right", 10, 272, 10)
  }

  scrollPrev(e) {
    const container = e.currentTarget.nextSibling
    sideScroll(container, "left", 10, 272, 10)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.disableScroll) {
      window.setTimeout(
        function () {
          this.setState({
            disableScroll: false,
          })
        }.bind(this),
        40
      )
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.reCalc)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reCalc)
  }

  render() {
    const { eventList } = this.props

    return (
      <div className={classNames("wrapper", this.props.className)}>
        <div className="card-container">
          <div
            ref={this.scrollContainerRef}
            className="scrolling-wrapper"
            onScroll={this.handleScroll}
          >
            {eventList.map((event, i) => (
              <EventCard
                event={event}
                className="in-carousel"
                velvetOnClick={() => this.props.carouselOnClick()}
              />
            ))}
            {this.props.customTile}
            {eventList.map((event, i) => (
              <EventCard
                event={event}
                className={`in-carousel is-clone-${this.props.carouselId}`}
                velvetOnClick={() => this.props.carouselOnClick()}
              />
            ))}
          </div>

          {/*<div>  debugging values
            <div>ScrollPos:&nbsp;{this.state.scrollPos}</div>
            <div>ClonesWidth:&nbsp;{this.getClonesWidth()}</div>
            <div>ScrollWidth:&nbsp;{this.state.scrollWidth}</div>
        </div>*/}
        </div>
      </div>
    )
  }
}

export default CardContainer
