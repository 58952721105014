import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import classNames from "classnames"
import { Layout } from "../components"
import {
  AngleDown,
  Circle,
  FilledCircle,
  Person,
  Ticket,
  USDC,
  Venue,
} from "../css/icons"
import { formatDate } from "../helpers/formatDate"
import { getEventById } from "../reducers/eventSlice"
import styles from "./EventPage.module.scss"
import {
  getAllTicketGroupsForEvent,
  setSelectedTicketGroup,
  clearTicketGroups,
} from "../reducers/ticketSlice"

const EventPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const event = useSelector((state) => state.events.selectedEvent)
  const eventLoading = useSelector((state) => state.events.eventLoading)
  const ticketGroups = useSelector((state) => state.tickets.ticketGroups)
  const [selectedQuantity, setSelectedQuantity] = useState(2)
  const [showQuantity, setShowQuantity] = useState(false)
  const [filteredTicketGroups, setFilteredTicketGroups] = useState([])

  useEffect(() => {
    dispatch(getEventById(params.event_id))
    dispatch(getAllTicketGroupsForEvent(params.event_id))

    return () => {
      dispatch(clearTicketGroups())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filteredTicketGroups.length == 0) {
      filterTicketGroups(selectedQuantity)
    }
  }, [ticketGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterTicketGroups = (quantity) => {
    // let's do the filtering right here..... each ticket group
    // needs to have quantity and
    let filteredGroups = []
    for (let i in ticketGroups) {
      let tg = ticketGroups[i]
      if (tg.ticket_quantity < quantity) {
        continue
      } else if (tg.ticket_quantity == quantity) {
        filteredGroups.push(tg)
      } else if (tg.ticket_quantity > quantity) {
        if (tg.split_type === "ANY") {
          filteredGroups.push(tg)
        } else if (tg.split_type === "NEVERLEAVEONE") {
          if (tg.ticket_quantity - 1 > quantity) {
            filteredGroups.push(tg)
          }
        }
      } else {
        console("Bug in logic. Tell Scott")
      }
    }
    setFilteredTicketGroups(filteredGroups)
  }

  // todo: make a better loading screen
  if (eventLoading) return <div>Loading...</div>


  console.log("event", event)

  return (
    <Layout>
      <div className={styles.eventPageContainer}>
        <div className={styles.ticketListContainer}>
          <div className={styles.eventInfoContainer}>
            <div className={styles.eventInfoMobileWrapper}>
              <div className={styles.eventInfo}>
                <div className={styles.date}>
                  {event?.date_formatted}
                </div>
                <h1>{event?.short_title || event?.title}</h1>
                <div className={styles.venue}>
                  <Venue />
                  {event?.venue_name}, {event?.venue_city}, {event?.venue_state}
                </div>
              </div>
            </div>
            <div className={styles.mobileVenueImageContainer}>
              <img
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
                alt="Placeholder "
                className={styles.venueImage}
              />
            </div>
          </div>
          <div className={styles.filters}>
            <div className={styles.optionsContainer}>
              <div
                className={styles.select}
                onClick={() => setShowQuantity(!showQuantity)}
              >
                <Person className={styles.iconLeft} />
                {selectedQuantity}{" "}
                {selectedQuantity === 1 ? "ticket" : "tickets"}
                <AngleDown className={styles.iconRight} />
              </div>

              {showQuantity && (
                <div className={styles.selectOptions}>
                  {[...Array(8)].map((e, i) => (
                    <div
                      className={classNames(
                        styles.selectItem,
                        selectedQuantity === i + 1 && styles.selected
                      )}
                      key={i}
                      onClick={() => {
                        setSelectedQuantity(i + 1)
                        setShowQuantity(false)
                        filterTicketGroups(i + 1)
                      }}
                    >
                      <span>
                        {i + 1} {i === 0 ? "ticket" : "tickets"}
                      </span>
                      {selectedQuantity === i + 1 ? (
                        <FilledCircle />
                      ) : (
                        <Circle />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={styles.ticketGroupList}>
            {filteredTicketGroups?.map((ticketGroup) => (
              <div
                className={styles.ticketInfoListItem}
                onClick={() => {
                  dispatch(setSelectedTicketGroup(ticketGroup))
                  history.push(
                    `/checkout/${params.event_id}/${ticketGroup.id}?quantity=${selectedQuantity}`
                  )
                }}
              >
                <div className={styles.ticketInfo}>
                  <div className={styles.ticketType}>
                    {ticketGroup?.section && `Sec ${ticketGroup?.section}, `}
                    {ticketGroup?.row && `Row ${ticketGroup?.row}`}
                  </div>
                  <div className={styles.ticketQuantity}>
                    <Ticket />
                    {selectedQuantity}
                  </div>
                </div>
                <div className={styles.ticketActions}>
                  <p className={styles.priceDescription}>
                    <USDC />
                    <span>${ticketGroup.cost} USDC</span>{" "}
                  </p>
                  ea.
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
            alt="Placeholder "
            className={styles.venueImage}
          />
        </div>
      </div>
    </Layout>
  )
}

export default EventPage
