import classNames from "classnames"
import { useState, useEffect } from "react"
import { Button, EventCard, Layout, Search } from "../components"
import styles from "./Home.module.scss"
import Tile from "../components/Tile/Tile"
import { useWallet } from "@solana/wallet-adapter-react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { PublicKey, Connection } from "@solana/web3.js"
import {
  getAllEvents,
  getEventsByLocation,
  getFeaturedEvents,
  getRecentlyAddedEvents,
} from "../reducers/eventSlice"
import {
  setConfig,
  PDA,
  Operator,
  WalletAdapterIdentity,
  KeypairIdentity,
  Currency,
  TokenAccount,
  MerkelTreeAuthAccount,
  CNFT,
} from "@captainxyz/solana-core"

const locationList = [
  "Chicago",
  "New York",
  "Los Angeles",
  "Miami",
  "Denver",
  "Boston",
  "Phoenix",
  "Atlanta",
]

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const adapter = useWallet()
  const [showCrystal, setShowCrystal] = useState(false)
  const [showGold, setShowGold] = useState(false)

  const eventsByLocation = useSelector((state) => state.events.eventsByLocation)
  const eventsByLocationSuccess = useSelector(
    (state) => state.events.eventsByLocationSuccess
  )
  const recentlyAddedEvents = useSelector(
    (state) => state.events.recentlyAddedEvents
  )
  const featuredEvents = useSelector((state) => state.events.featuredEvents)

  useEffect(() => {
    window.scrollTo(0, 0)
    checkForTiles()
    dispatch(getAllEvents())

    locationList.forEach((location) => {
      dispatch(getEventsByLocation({ cbsa: location }))
    })

    dispatch(getRecentlyAddedEvents({ just_added: true }))
    dispatch(getFeaturedEvents({ featured: true }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!eventsByLocationSuccess) {
      locationList.forEach((location) => {
        dispatch(getEventsByLocation({ cbsa: location }))
      })
    }
  }, [eventsByLocationSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForTiles = async () => {
    if (!adapter) {
      console.log("Adapter not set. Not checking for tiles")
      return
    }

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity)
    const cnfts = await CNFT.find(operator)

    // see if they have an XP account
    let hasXpAccount = false
    let pubKey = new PublicKey(adapter.publicKey)
    let mint = new PublicKey("A8ieiDudXNC3Cj2t3jGqivop1fUqccWN3h7XJXzBsc13")
    let tokenAddress = PDA.token(new PublicKey(mint), pubKey)
    try {
      let token = await TokenAccount.init(operator, tokenAddress)
      hasXpAccount = true
    } catch (err) {
      console.log("No XP error", err)
    }

    if (!hasXpAccount) {
      for (const cnft of cnfts) {
        if (cnft.json.name === "XP Crystal Chip") {
          setShowCrystal(true)
        }
        if (cnft.json.name === "XP Gold Chip") {
          setShowGold(true)
        }
      }
    }
  }

  return (
    <Layout
      className={styles.homePageContainer}
      showCrystal={showCrystal}
      showGold={showGold}
    >
      <div className={styles.homeHeaderContainer}>
        <div className={styles.searchContainer}>
          <Search isMobile />
        </div>
        <div className={styles.homeHeroContainer}>
          <img
            alt="XP Logo"
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
            className={styles.homeHeroLogo}
          />
          <p className={styles.homeHeroSubheading}>
            🎤 *Tap* *Tap* Check 1 - 2 Check Welcome to Sound Check Everyone!
          </p>
          <h1 className={styles.homeHeading}>You're in Early</h1>
          <p className={styles.homeHeroSubheadingSmall}>
            Be one of the first to explore our beta site & buy real tickets to
            your next event on chain.
          </p>
        </div>
      </div>
      <div className={styles.homeDiscoveryContainer}>
        <div className={styles.homeDiscoveryInner}>
          <div className={styles.homeDiscoveryRow}>
            <div className={styles.discoverySectionHeader}>
              <h2>Featured</h2>
              <Button
                compact
                className={styles.button}
                variant="gray"
                onClick={() => history.push("/events?featured=true")}
              >
                See All
              </Button>
            </div>
            <div className={styles.discoverySectionList}>
              {featuredEvents?.map((event, i) => {
                if (i > 1) return null
                return (
                  <EventCard
                    key={event.name}
                    className={styles.card2w}
                    event={event}
                  />
                )
              })}
            </div>
          </div>
          <div className={styles.homeDiscoveryRow}>
            <div className={styles.discoverySectionHeader}>
              <h2>By City</h2>
              <Button
                compact
                onClick={() => history.push("/events")}
                className={styles.button}
                variant="gray"
              >
                See All
              </Button>
            </div>
            <div
              className={classNames(
                styles.discoverySectionList,
                styles.locationList
              )}
            >
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/newyork.png"
                numberOfShows={eventsByLocation["New York"]?.length}
                onClick={() => history.push("/events?location=New+York")}
                title="New York"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/losangeles.png"
                numberOfShows={eventsByLocation["Los Angeles"]?.length}
                onClick={() => history.push("/events?location=Los+Angeles")}
                title="Los Angeles"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/chicago1.png"
                numberOfShows={eventsByLocation["Chicago"]?.length}
                onClick={() => history.push("/events?location=Chicago")}
                title="Chicago"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/miami-tdx.png"
                numberOfShows={eventsByLocation["Miami"]?.length}
                onClick={() => history.push("/events?location=Miami")}
                title="Miami"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/denver.png"
                numberOfShows={eventsByLocation["Denver"]?.length}
                onClick={() => history.push("/events?location=Denver")}
                title="Denver"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/boston.png"
                numberOfShows={eventsByLocation["Boston"]?.length}
                onClick={() => history.push("/events?location=Boston")}
                title="Boston"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/phoenix.png"
                numberOfShows={eventsByLocation["Phoenix"]?.length}
                onClick={() => history.push("/events?location=Phoenix")}
                title="Phoenix"
              />
              <Tile
                className={classNames(styles.card4w)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/atlanta.png"
                numberOfShows={eventsByLocation["Atlanta"]?.length}
                onClick={() => history.push("/events?location=Atlanta")}
                title="Atlanta"
              />
            </div>
          </div>

          {/* <div className={styles.homeDiscoveryRow}>
            <div className={styles.discoverySectionHeader}>
              <h2>Browse By Genre</h2>
              <Button
                compact
                onClick={() => history.push("/events?eventType=Concerts")}
              >
                See All
              </Button>
            </div>
            <div className={styles.discoverySectionList}>
              <Tile
                className={classNames(styles.card4w, styles.card2h)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/rock.png"
                numberOfShows={249}
                onClick={() =>
                  history.push(
                    "/events?eventType=Concerts&selectedFilters=Rock"
                  )
                }
                subtitle="Rock"
                title="Rock"
              />
              <Tile
                className={classNames(styles.card4w, styles.card2h)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/pop.png"
                numberOfShows={249}
                onClick={() =>
                  history.push("/events?eventType=Concerts&selectedFilters=Pop")
                }
                subtitle="Pop"
                title="Pop"
              />
              <Tile
                className={classNames(styles.card4w, styles.card2h)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/hiphop.png"
                numberOfShows={249}
                onClick={() =>
                  history.push(
                    "/events?eventType=Concerts&selectedFilters=Hip-Hop"
                  )
                }
                subtitle="Hip-Hop"
                title="Hip-Hop"
              />
              <Tile
                className={classNames(styles.card4w, styles.card2h)}
                image="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/edm.png"
                numberOfShows={249}
                onClick={() =>
                  history.push("/events?eventType=Concerts&selectedFilters=EDM")
                }
                subtitle="EDM"
                title="Electronic"
              />
            </div>
          </div> */}

          {recentlyAddedEvents?.length > 0 && (
            <div className={styles.homeDiscoveryRow}>
              <div className={styles.discoverySectionHeader}>
                <h2>Just Announced</h2>
                <Button
                  compact
                  className={styles.button}
                  variant="gray"
                  onClick={() =>
                    history.push(
                      "/events?eventType=Concerts&sort_by=created_at&sort_order=desc"
                    )
                  }
                >
                  See All
                </Button>
              </div>
              <div className={styles.discoverySectionList}>
                {recentlyAddedEvents.map((event, i) => {
                  if (i > 3) return null
                  return (
                    <EventCard
                      event={event}
                      className={styles.card4w}
                      key={event.name}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Home
