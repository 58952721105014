export const LocationArrow = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9092 1.92488L2.08169 6.92221C0.83249 7.50528 1.24889 9.33755 2.58143 9.33755H7.16236V13.9185C7.16236 15.251 8.99463 15.6677 9.57769 14.4182L14.575 3.59075C14.9914 2.59102 13.9086 1.50822 12.9092 1.92488Z"
      fill="#FAFAFA"
    />
  </svg>
)
