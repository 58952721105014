import { useState } from "react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { Button, Modal } from "../components"
import { CloseCircle, Phone, Question, Wallet } from "../css/icons"
import styles from "./SignInModal.module.scss"
import PhoneInput from "./Phone"
import Verification from "./Verification"
import postToSlack from "../postToSlack"

const Api = require("../api")

const SignInModal = ({ onClose }) => {
  const [phoneSelected, setPhoneSelected] = useState(false)
  const [formattedNumber, setFormattedNumber] = useState("")

  const [number, setNumber] = useState("")
  const [authUUID, setAuthUUID] = useState("")
  const [codeSent, setCodeSent] = useState(false)

  const validateAndSendText = () => {
    let stripped = number.replace(/ /g, "")
    let message = `${stripped} is starting to login`
    postToSlack(message, "xp")
    Api.callApi({
      endpoint: "/supperclub/get-login-code",
      method: "post",
      data: { phone: stripped, ticketdex:true },
      success: (resp) => {
        if (resp.success) {
          setAuthUUID(resp.auth_uuid)
          setCodeSent(true)
        } else {
          alert("Unknown Error")
        }
      },
      failure: (err) => {
        alert("Unknown Error")
      },
    })
  }

  const closeModal = () => {
    document.body.style.overflow = "auto"
    onClose()
  }

  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      width="medium"
    >
      {phoneSelected && !codeSent && (
        <PhoneInput
          formattedNumber={formattedNumber}
          number={number}
          onClose={closeModal}
          setFormattedNumber={setFormattedNumber}
          setNumber={setNumber}
          setPhoneSelected={setPhoneSelected}
          validateAndSendText={validateAndSendText}
        />
      )}
      {phoneSelected && codeSent && (
        <Verification
          onClose={closeModal}
          phoneNumber={formattedNumber}
          setCodeSent={setCodeSent}
          authUUID={authUUID}
          validateAndSendText={validateAndSendText}
        />
      )}
      {!phoneSelected && !codeSent && (
        <div className={styles.signInModal}>
          <div className={styles.modalHeader}>
            <span>🎟️</span>

            <CloseCircle
              onClick={() => {
                document.body.style.overflow = "auto"
                onClose()
              }}
            />
          </div>
          <h1>Sign In</h1>
          <p>Connect your wallet to securely store and manage your tickets</p>
          <WalletMultiButton>
            <Wallet />
            Connect Wallet
          </WalletMultiButton>
          <br/><br/>
          <Button
            fullWidth
            variant="gray"
            className={styles.modalButton}
            onClick={() => setPhoneSelected(true)}
          >
            <Phone /> Continue with Phone
          </Button>
          <a
            className={styles.modalLink}
            href="https://docs.solana.com/wallet-guide"
          >
            What's a wallet? <Question />
          </a>
        </div>
      )}
    </Modal>
  )
}

export default SignInModal
