import _WebSocket from "ws";

// @ts-ignore
if (typeof global !== "undefined") global.WebSocket ??= _WebSocket;

/**
 * @group Coinbase exchange feed ticker
 */
export type TickerCallback = (price: number) => void;

/**
 * Provides real time SOL-USD price updates from Coinbase exchange feed.
 *
 * @example
 * // Create subscription
 * const subscriptionId = Ticker.subscribe(
 *    (price: number) => console.log(`1 SOL = ${price} USD`)
 * );
 *
 * // Cancel subscription
 * Ticker.unsubscribe(subscriptionId);
 *
 * // Cancel all subscriptions on application shutdown
 * Ticker.close();
 *
 * @see
 * {@link Ticker.subscribe},
 * {@link Ticker.unsubscribe},
 * {@link Ticker.close}.
 *
 * @group Coinbase exchange feed ticker
 */
export class Ticker {
  private _lastId: number;
  private _subscriptions: Map<number, TickerCallback>;

  private constructor() {
    this._lastId = 0;
    this._subscriptions = new Map();
  }

  private static _instance: Ticker;
  private static getInstance(): Ticker {
    if (this._instance == null) this._instance = new this();
    return this._instance;
  }

  /**
   * Checks whether the ticker is active,
   * i.e. it has subscribers, its feed is open, and feed supervisor is active.
   */
  public static get isActive(): boolean {
    return this.getInstance().isActive;
  }
  private get isActive(): boolean {
    return (
      Boolean(this._supervisor) &&
      Boolean(this._feed) &&
      Boolean(this._subscriptions.size)
    );
  }

  /**
   * Unsubscribes all callback functions from the ticker
   * and closes Coinbase exchange feed.
   */
  public static close(): void {
    return this.getInstance().close();
  }
  private close(): void {
    this._subscriptions.clear();
    this._deactivate();
  }

  /**
   * Subscribes callback function to the ticker.
   *
   * Coinbase exchange feed will be opened,
   * when first subscription is added.
   *
   * @return Subscription ID, which is used to {@link unsubscribe} the callback.
   */
  public static subscribe(callback: TickerCallback): number {
    return this.getInstance().subscribe(callback);
  }
  private subscribe(callback: TickerCallback): number {
    const id = this._lastId++;
    this._subscriptions.set(id, callback);
    this._activate();
    return id;
  }

  /**
   * Unsubscribes callback function from the ticker
   * using previously obtained subscription ID.
   *
   * Coinbase exchange feed will be closed,
   * when last subscription is cancelled.
   */
  public static unsubscribe(id: number): void {
    return this.getInstance().unsubscribe(id);
  }
  private unsubscribe(id: number): void {
    this._subscriptions.delete(id);
    if (!this._subscriptions.size) this._deactivate();
  }

  private _feed?: WebSocket;
  private _supervisor?: NodeJS.Timer;

  /**
   * Opens Coinbase exchange feed for SOL-USD price updates
   * and sets up its supervisor to ensure the feed stays alive.
   *
   * For details of Coinbase API see:
   * https://docs.cloud.coinbase.com/exchange/docs/websocket-channels#ticker-channel
   */
  private _activate(): void {
    if (
      !this._feed ||
      this._feed.readyState == WebSocket.CLOSED ||
      this._feed.readyState == WebSocket.CLOSING
    ) {
      this._feed = new WebSocket("wss://ws-feed.exchange.coinbase.com");
      this._feed.onopen = () => {
        (this._feed as WebSocket).send(
          JSON.stringify({
            type: "subscribe",
            product_ids: ["SOL-USD"],
            channels: ["ticker_batch"],
          })
        );
      };
      this._feed.onmessage = (event: MessageEvent) => {
        if (typeof event.data == "string") {
          const data = JSON.parse(event.data);
          if (data.type == "ticker" && data.product_id == "SOL-USD") {
            const price = Number(data.price);
            for (let [_, callback] of this._subscriptions) callback(price);
          }
        }
      };
    }
    if (!this._supervisor) {
      this._supervisor = setInterval(() => this._activate(), 5000);
    }
  }

  /**
   * Closes Coinbase exchange feed and shuts down its supervisor.
   */
  private _deactivate(): void {
    if (this._supervisor) {
      clearInterval(this._supervisor);
      delete this._supervisor;
    }
    if (
      this._feed &&
      this._feed.readyState != WebSocket.CLOSED &&
      this._feed.readyState != WebSocket.CLOSING
    ) {
      this._feed.close();
      delete this._feed;
    }
  }
}
