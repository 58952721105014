import styles from "./SignInModal.module.scss"
import { AngleLeft, CloseCircle, Phone, X } from "../css/icons"
import { Button, Input } from "../components"

const PhoneInput = ({
  formattedNumber,
  number,
  onClose,
  setFormattedNumber,
  setNumber,
  setPhoneSelected,
  validateAndSendText,
}) => {
  function phoneFormat(input) {
    if (!input) return
    //returns (###) ###-####
    input = input.replace(/\D/g, "")
    var size = input.length
    if (window.isDeleting) {
      if (size > 1) {
        input = "(" + input
      }
      if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
      }
      if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
      }
      if (size === 3) {
        input = input.slice(0, 4)
      }
    } else {
      if (size > 0) {
        input = "(" + input
      }
      if (size >= 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
      }
      if (size >= 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
      }
    }
    return input
  }

  const handleChange = (e) => {
    let value = e.target.value?.replace(/[()-]/g, "")
    if (value && value.length && value.length - number > 5) {
      setFormattedNumber("")
      return
    }
    if (value.length < 12) {
      setNumber(e.target.value?.replace(/[()-]/g, ""))
      setFormattedNumber(phoneFormat(e.target.value?.replace(/[()-]/g, "")))
    }
  }

  const disabled = number?.length !== 11
  return (
    <div className={styles.signInModal}>
      <div className={styles.buttonRow}>
        <CloseCircle onClick={() => setPhoneSelected(false)}>
          <AngleLeft />
        </CloseCircle>
        <CloseCircle onClick={() => onClose()}>
          <X />
        </CloseCircle>
      </div>
      <h1>Continue with phone</h1>
      <p>Connect your wallet to securely store and manage your tickets</p>
      <label className={styles.label}>
        <Phone /> My mobile phone number
      </label>
      <Input
        className={styles.input}
        disableAutocomplete={true}
        placeholder="(555) 555-5555"
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            validateAndSendText()
          }
        }}
        value={formattedNumber}
        type="search" // disables autofill until we can find a better solution
      ></Input>
      <Button
        disabled={disabled}
        onClick={() => {
          validateAndSendText()
        }}
        variant="blue"
        fullWidth
      >
        Continue
      </Button>
    </div>
  )
}

export default PhoneInput
