import { useEffect, useState } from "react"
import classNames from "classnames"
import { Button, Layout } from "../components"
import styles from "./EventList.module.scss"
import {
  Calendar,
  Circle,
  FilledCircle,
  LocationArrow,
  XCircle,
} from "../css/icons"
import { getFilteredEvents, setSelectedEvent } from "../reducers/eventSlice"
import { CustomDatePicker, EventCard } from "../components"

import { useSelector, useDispatch } from "react-redux"
import { formatLocationName } from "../helpers/location"
import { setPreferredLocation } from "../reducers/userSlice"
import Loading from "../Loading/Loading"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"

const EventList = () => {
  const eventList = useSelector((state) => state.events.eventList)
  const eventsLoading = useSelector((state) => state.events.eventsLoading)
  const filteredEvents = useSelector((state) => state.events.filteredEventList)
  const filteredEventsLoading = useSelector(
    (state) => state.events.filteredEventsLoading
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const routeParams = useParams()

  // const [selectedFilters, setSelectedFilters] = useState([])
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const [locations, setLocations] = useState([])

  const [location, setLocation] = useState("")
  const [showLocationMenu, setShowLocationMenu] = useState(false)
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [venue, setVenue] = useState({ venueId: null, venueName: null })
  const [query, setQuery] = useState("")
  const [numberOfResults, setNumberOfResults] = useState(24)
  const [featured, setFeatured] = useState(false)

  const [eventType, setEventType] = useState([])

  const eventTypes = ["music", "comedy", "theater", "sports"]

  // const concertGenres = [
  //   "Rock",
  //   "Pop",
  //   "Hip-Hop",
  //   "EDM",
  //   "Country",
  //   "World",
  //   "Indie",
  //   "Alternative",
  // ]

  useEffect(() => {
    window.scrollTo(0, 0)
    // clear the selected event when returning to list view
    dispatch(setSelectedEvent(null))

    if (params.location) {
      setLocation(params.location)
      dispatch(setPreferredLocation(params.location))
    }
    if (params.eventType) {
      setEventType(params.eventType.split(","))
    }
    if (params.venue_id) {
      setVenue({ venueId: params.venue_id, venueName: params.venue_name })
    }
    // if (params.selectedFilters) {
    //   setSelectedFilters(params.selectedFilters.split(","))
    // }

    if (routeParams?.query) {
      setQuery(routeParams.query)
    }

    if (params.featured) {
      setFeatured(true)
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLocations()
  }, [filteredEvents, filteredEventsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (routeParams?.query) {
      setQuery(routeParams.query)
    }
  }, [routeParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const getLocations = () => {
    const locationList = []
    filteredEvents?.forEach((event) => {
      if (event.venue_cbsa && !locationList.includes(event.venue_cbsa)) {
        locationList.push(event.venue_cbsa)
      }
    })

    const alphabetizedList = locationList.sort((a, b) => a.localeCompare(b))

    setLocations(alphabetizedList)
  }

  const removeEventType = (filter) => {
    const eventTypeList = [...eventType]
    if (eventTypeList.includes(filter)) {
      eventTypeList.splice(eventTypeList.indexOf(filter), 1)
    }

    setEventType(eventTypeList)
  }

  // this was used for the genre filters, keeping in case we ever get genre info to put back in
  // const removeFilter = (filter) => {
  //   const filterList = [...selectedFilters]
  //   if (filterList.includes(filter)) {
  //     filterList.splice(filterList.indexOf(filter), 1)
  //   }

  //   setSelectedFilters(filterList)
  // }

  const displayEventHeader = () => {
    let header = ""
    if (venue.venueName) {
      return `Events at ${venue.venueName}`
    }
    if (query) {
      return `Results for ${query}`
    }

    const uppercase = (str) => {
      // const str = "flexiple"
      return str.charAt(0).toUpperCase() + str.slice(1)
    }

    switch (eventType.length) {
      case 3:
        header = `${uppercase(eventType[0])}, ${uppercase(
          eventType[1]
        )}, and ${uppercase(eventType[2])}`
        break
      case 2:
        header = `${uppercase(eventType[0])} and ${uppercase(eventType[1])}`
        break
      case 1:
        header = `${uppercase(eventType[0])}`
        break
      default:
        header = "Events"
    }
    const format = header.replace("Music", "Concerts")
    return format
  }

  const [eventHeader, setEventHeader] = useState("Events")

  useEffect(() => {
    setEventHeader(displayEventHeader())
    if (
      eventType?.length ||
      location ||
      startDate ||
      endDate ||
      featured ||
      query ||
      venue?.venueId
    ) {
      dispatch(
        getFilteredEvents({
          event_type: eventType,
          cbsa: location,
          min_date: startDate?.toISOString().split("T")[0],
          max_date:
            endDate?.toISOString().split("T")[0] ||
            startDate?.toISOString().split("T")[0] ||
            null,
          venue_id: venue.venueId,
          featured,
          query,
        })
      )
    } else {
      dispatch(getFilteredEvents({ limit: 100 }))
    }
  }, [eventType, location, startDate, endDate, venue.venueId, query, featured]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: HANDLE NO EVENTS FOUND

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  return (
    <Layout>
      <div className={styles.eventListHeader}>
        <div className={styles.eventListHeroContainer}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/eventgif.gif"
            alt="Event"
          />
          <div className={styles.eventTagContainer}>
            <div className={classNames(styles.tagRow, styles.topRow)}>
              <div className={styles.menuContainer}>
                <Button
                  leftIcon={<LocationArrow />}
                  variant="gray"
                  compact
                  rightIcon={location && <XCircle className={styles.close} />}
                  rightIconOnClick={(e) => {
                    e.stopPropagation()
                    setLocation(null)
                    dispatch(setPreferredLocation(null))
                    setShowLocationMenu(false)
                  }}
                  className={classNames(
                    styles.button,
                    !!location ? styles.selected : styles.unselected
                  )}
                  onClick={() => {
                    setShowLocationMenu(!showLocationMenu)
                  }}
                >
                  {location ? formatLocationName(location) : "Location"}
                </Button>
                {showLocationMenu && (
                  <ul className={styles.locationList}>
                    {locations.map((loc) => {
                      return (
                        <li
                          key={loc}
                          className={classNames(
                            location === loc && styles.selectedLocation
                          )}
                          onClick={() => {
                            setLocation(loc)
                            dispatch(setPreferredLocation(loc))
                            setShowLocationMenu(false)
                          }}
                        >
                          {formatLocationName(loc)}

                          {location === loc ? <FilledCircle /> : <Circle />}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
              <div className={styles.menuContainer}>
                <Button
                  leftIcon={<Calendar />}
                  compact
                  variant="gray"
                  rightIcon={startDate && <XCircle className={styles.close} />}
                  rightIconOnClick={(e) => {
                    e.stopPropagation()
                    setShowDateMenu(false)
                    setStartDate(null)
                    setEndDate(null)
                  }}
                  className={classNames(
                    styles.button,
                    !!startDate ? styles.selected : styles.unselected
                  )}
                  onClick={() => {
                    setShowDateMenu(!showDateMenu)
                  }}
                >
                  {!startDate && "Date"}
                  {startDate &&
                    startDate.toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}
                  {showEndDate &&
                    ` - ${endDate?.toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}`}
                </Button>
                {showDateMenu && (
                  <div className={styles.datePickerContainer}>
                    <CustomDatePicker
                      containerStartDate={startDate}
                      setDates={(start, end) => {
                        setStartDate(start)
                        setEndDate(end)
                      }}
                      seStartDate={setStartDate}
                      containerEndDate={endDate}
                      setEndDate={setEndDate}
                      onClose={() => setShowDateMenu(false)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.concertRow}>
              <div className={styles.tagRow}>
                {eventTypes.map((type) => (
                  <Button
                    variant="gray"
                    compact
                    className={classNames(
                      styles.button,
                      eventType.includes(type)
                        ? `${styles.selected} ${styles.secondaryFilterSelected}`
                        : `${styles.unselected} ${styles.secondaryFilterUnselected}`
                    )}
                    key={type}
                    onClick={() => {
                      if (eventType.includes(type)) {
                        removeEventType(type)
                      } else {
                        setEventType([...eventType, type])
                      }
                    }}
                    rightIcon={
                      eventType.includes(type) && (
                        <XCircle className={styles.close} />
                      )
                    }
                  >
                    {type === "music" ? "Concerts" : type}
                  </Button>
                ))}
              </div>
            </div>
            {/* {eventType.includes("Concerts") && (
              <div className={styles.tagRow}>
                <div
                  className={classNames(
                    styles.filterOption,
                    selectedFilters.length === 0 && styles.selectedFilterOption
                  )}
                  onClick={() => {
                    setSelectedFilters([])
                  }}
                >
                  All Genres
                </div>
                {concertGenres.map((genre) => (
                  <div
                    className={classNames(
                      styles.filterOption,
                      selectedFilters.includes(genre) &&
                        styles.selectedFilterOption
                    )}
                    onClick={() => {
                      if (selectedFilters.includes(genre)) {
                        removeFilter(genre)
                      } else {
                        setSelectedFilters([...selectedFilters, genre])
                      }
                    }}
                  >
                    {genre}
                    {selectedFilters.includes(genre) && <X />}
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className={styles.eventListBody}>
        {eventsLoading || filteredEventsLoading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.bodyHeader}>
              <h2>
                <span className={styles.headerGray}>{eventHeader}</span>{" "}
                {location &&
                  `near ${formatLocationName(location).toUpperCase()}`}
                {startDate && (
                  <span className={styles.headerGray}>
                    {startDate && endDate ? (
                      <>
                        {" "}
                        <span className={styles.lowercase}>from </span>
                        {startDate?.toLocaleDateString("en-us", {
                          month: "short",
                          day: "numeric",
                        })}{" "}
                        -{" "}
                        {endDate?.toLocaleDateString("en-us", {
                          month: "short",
                          day: "numeric",
                        })}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className={styles.lowercase}>on</span>{" "}
                        {startDate?.toLocaleDateString("en-us", {
                          month: "short",
                          day: "numeric",
                        })}
                      </>
                    )}
                  </span>
                )}
              </h2>
              <Button
                variant="gray"
                className={styles.clear}
                onClick={() => {
                  setStartDate(null)
                  setEndDate(null)
                  setLocation(null)
                  setEventType([])
                  setFeatured(false)

                  history.push("/events")
                }}
              >
                Clear Filters
              </Button>
            </div>
            {filteredEvents?.length > 0 && (
              <div className={styles.eventContainer}>
                {filteredEvents.map((event, i) => {
                  if (i >= numberOfResults) return null
                  return (
                    <EventCard
                      key={event.event_id}
                      className={classNames(
                        styles.card4w,
                        styles.eventListCard
                      )}
                      event={event}
                    />
                  )
                })}
              </div>
            )}

            {filteredEvents?.length >= numberOfResults && (
              <Button
                variant="gray"
                className={styles.showMoreButton}
                onClick={() => setNumberOfResults(numberOfResults + 24)}
              >
                Show More
              </Button>
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default EventList
