import { Modal, Button } from "../components"
import { CloseCircle } from "../css/icons"
import styles from "./SellTicketsModal.module.scss"

const SellTicketsModal = ({ close, unseal }) => (
  <Modal width="medium" onClose={() => close()}>
    <div className={styles.container}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <div className={styles.icon}>🏷️</div>
      <div className={styles.heading}>Sell your tickets - coming soon!</div>
      <div className={styles.textContainer}>
        Listing your tickets for sale on XP is coming soon. Stay tuned for this
        upcoming feature and get ready to effortlessly sell your tickets on the
        decentralized exchange.
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="gray" onClick={() => close()}>
          Not Now
        </Button>
        <Button variant="blue" onClick={() => unseal()}>
          Unseal
        </Button>
      </div>
    </div>
  </Modal>
)

export default SellTicketsModal
