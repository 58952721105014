export const Question = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 9C17 13.1431 13.6418 16.5 9.5 16.5C5.35816 16.5 2 13.1431 2 9C2 4.85936 5.35816 1.5 9.5 1.5C13.6418 1.5 17 4.85936 17 9ZM9.70126 3.97984C8.05317 3.97984 7.00202 4.6741 6.17659 5.908C6.06966 6.06786 6.10543 6.28346 6.2587 6.39968L7.30806 7.19534C7.46547 7.31471 7.68975 7.28631 7.81205 7.13117C8.35229 6.44595 8.72272 6.0486 9.545 6.0486C10.1628 6.0486 10.927 6.44622 10.927 7.04531C10.927 7.49822 10.5531 7.73081 9.9431 8.07281C9.23169 8.47161 8.29032 8.96797 8.29032 10.2097V10.3306C8.29032 10.5311 8.45281 10.6935 8.65323 10.6935H10.3468C10.5472 10.6935 10.7097 10.5311 10.7097 10.3306V10.2903C10.7097 9.42959 13.2254 9.39375 13.2254 7.06452C13.2254 5.31042 11.4059 3.97984 9.70126 3.97984V3.97984ZM9.5 11.4798C8.73291 11.4798 8.10887 12.1039 8.10887 12.871C8.10887 13.638 8.73291 14.2621 9.5 14.2621C10.2671 14.2621 10.8911 13.638 10.8911 12.871C10.8911 12.1039 10.2671 11.4798 9.5 11.4798Z"
      fill="#E5E6FF"
    />
  </svg>
)
