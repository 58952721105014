export const X = ({ onClick }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => onClick && onClick()}
  >
    <path
      d="M10.7055 9.29388C11.0961 9.68448 11.0961 10.3172 10.7055 10.7078C10.5118 10.9031 10.2555 11 9.9993 11C9.74307 11 9.48746 10.9024 9.29248 10.7071L5.99961 7.4159L2.70705 10.7063C2.51176 10.9031 2.25584 11 1.99992 11C1.744 11 1.4884 10.9031 1.29295 10.7063C0.902351 10.3157 0.902351 9.68292 1.29295 9.29232L4.58644 5.99883L1.29295 2.7069C0.902351 2.3163 0.902351 1.68354 1.29295 1.29295C1.68354 0.902351 2.3163 0.902351 2.7069 1.29295L5.99961 4.588L9.2931 1.29451C9.6837 0.903914 10.3165 0.903914 10.7071 1.29451C11.0976 1.6851 11.0976 2.31787 10.7071 2.70846L7.41356 6.00195L10.7055 9.29388Z"
      fill="#9C9DA1"
    />
  </svg>
)
