import {
  Keypair,
  Connection,
  Cluster,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
  PublicKey,
  AccountInfo,
} from "@solana/web3.js";

import { SOL, Amount } from "./currency";
import { Storage } from "./storage";
import { getConfig } from "./config";
import { Identity, KeypairIdentity } from "./identity";
import { BaseAccount } from "./account";
import { RpcClient } from "./helius";

/**
 * @group Operator
 */
export class Operator {
  /**
   * Name of Solana cluster the operator connected to:
   * * `devnet` — https://api.devnet.solana.com;
   * * `testnet` — https://api.testnet.solana.com;
   * * `mainnet-beta` — https://api.mainnet-beta.solana.com;
   * * `unittest` — http://localhost:8899.
   */
  public readonly cluster: Cluster | "unittest";

  /**
   * Operator's indentity to sign transactions.
   */
  public readonly identity: Identity | null = null;

  /**
   * Connection to Solana cluster.
   * @internal
   */
  public readonly connection: Connection;

  /**
   * Helius RPC client.
   */
  public readonly rpc: RpcClient;

  /**
   * File storage.
   * @internal
   */
  public readonly storage: Storage;

  /**
   * Returns activity state of the operator.
   *
   * Inactive operator cannot be used to perform any operation.
   *
   * @see {@link close}, {@link closeAll}
   */
  public get isActive(): boolean {
    return this._isActive;
  }
  protected _isActive: boolean;

  protected static _instances: Set<Operator> = new Set();

  /**
   * Constructs operator in testing mode,
   * i.e. connects to localhost.
   *
   * @param identity
   * Operator's identity to sign transactions.
   * If it is `null`, operator will work in read-only mode.
   * If it omitted (i.e. `undefined`), a new key pair will be generated.
   */
  public constructor(cluster: "unittest", identity?: Identity | null);

  /**
   * Constructs operator in production mode.
   *
   * @param cluster
   * Name of Solana cluster to connect:
   * * `devnet` — https://api.devnet.solana.com;
   * * `testnet` — https://api.testnet.solana.com;
   * * `mainnet-beta` — https://api.mainnet-beta.solana.com.
   *
   * @param identity
   * Operator's identity to sign transactions.
   * If it is `null`, operator will work in read-only mode.
   */
  public constructor(cluster: Cluster, identity: Identity | null);
  public constructor(
    cluster: Cluster | "unittest",
    identity?: Identity | null
  ) {
    this.cluster = cluster;
    this.identity =
      typeof identity == "undefined" ? KeypairIdentity.generate() : identity;
    this.connection = new Connection(
      getConfig(cluster).rpcEndpoint,
      "confirmed"
    );
    this.rpc = new RpcClient(this.connection);
    this.storage = Storage.init(this.cluster, this.identity);
    this._isActive = true;
    this._accounts = new Map();
    Operator._instances.add(this);
  }

  /**
   * Closes the operator.
   *
   * The method **must** be called at the end of operator lifetime
   * to {@link detach} and {@link BaseAccount#deactivate}
   * all active accounts from the operator and cleans up their resources.
   *
   * @see {@link isActive}, {@link closeAll}.
   */
  public async close(): Promise<void> {
    await Promise.all(
      [...this._accounts.values()].map((account) => account.deactivate(false))
    );
    this._accounts.clear();
    this._isActive = false;
    Operator._instances.delete(this);
  }

  /**
   * Closes all active operators and cleans up their resources.
   *
   * The method **must** be called at the end of program
   * to {@link detach} and {@link BaseAccount#deactivate}
   * all active accounts from all active operators and clean up their resources.
   *
   * @see {@link isActive}, {@link close}.
   */
  public static async closeAll(): Promise<void> {
    await Promise.all([...this._instances].map((instance) => instance.close()));
    this._instances.clear();
  }

  /**
   * Executes transaction.
   */
  public async execute(
    ...blocks: TransactionBlock[]
  ): Promise<TransactionResult> {
    if (!this.identity) throw new Error("Operator is in read-only mode");
    if (!this.isActive) throw new Error("Operator is inactive");

    const signers: Keypair[] = [];
    const allocate: PublicKey[] = [];
    const instructions: TransactionInstruction[] = [];
    for (let block of blocks) {
      if (block.signers) signers.push(...block.signers);
      if (block.allocate) allocate.push(...block.allocate);
      instructions.push(...block.instructions);
    }

    const { blockhash } = await this.connection.getLatestBlockhash();
    const message = new TransactionMessage({
      payerKey: this.identity.publicKey,
      recentBlockhash: blockhash,
      instructions,
    }).compileToV0Message();

    const fee = await this.connection.getFeeForMessage(message);

    const transaction = new VersionedTransaction(message);
    let signature: string;

    signature = await this.identity.sendTransaction(
      transaction,
      this.connection,
      {
        signers: signers,
      }
    );
    await this.confirm(signature);

    const accounts: Map<string, AccountInfo<Buffer>> = new Map();
    let rent = 0;
    // Race condition is possible here,
    // so we load accounts in several attempts with 1 sec timeout between them.
    let loadingAddresses = [...allocate];
    const missedAddresess: PublicKey[] = [];
    for (let attempt = 0; attempt < 10; attempt++) {
      const accountsInfo = await this.connection.getMultipleAccountsInfo(
        loadingAddresses
      );
      for (let i = 0; i < loadingAddresses.length; i++) {
        const address = loadingAddresses[i];
        const accountInfo = accountsInfo[i];
        if (!accountInfo) {
          missedAddresess.push(address);
        } else {
          accounts.set(address.toString(), accountInfo);
          rent += accountInfo.lamports;
        }
      }
      if (!missedAddresess.length) break;
      loadingAddresses = [...missedAddresess];
      missedAddresess.length = 0;
      if (this.cluster !== "unittest") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    const missedCount = allocate.length - accounts.size;
    if (missedCount) {
      console.warn(
        `${missedCount} of ${allocate.length} allocated accounts has not been loaded`
      );
    }

    return {
      signature,
      accounts,
      fee: SOL.amountFromQty(fee.value ?? 0),
      rent: SOL.amountFromQty(rent),
    };
  }

  /**
   * Confirms transaction by its signature.
   */
  public async confirm(signature: string): Promise<void> {
    if (!this.isActive) throw new Error("Operator is inactive");

    await this.connection.confirmTransaction({
      signature,
      ...(await this.connection.getLatestBlockhash()),
    });
  }

  /**
   * Internal accounts storage.
   */
  protected _accounts: Map<string, BaseAccount>;

  /**
   * Attaches active account to the operator.
   *
   * @internal
   *
   * The method is not intended to be used directly.
   * It is called by account intializers.
   */
  public async attach(account: BaseAccount): Promise<void> {
    if (!this.isActive) throw new Error("Operator is inactive");
    if (!account.isActive) return;
    if (account.operator !== this) {
      throw new Error("Account uses another operator");
    }
    const address = account.address.toString();
    const existing = this._accounts.get(address);
    if (existing && existing !== account) {
      await existing.deactivate(false);
    }
    this._accounts.set(address, account);
  }

  /**
   * Detaches and deactivates account from the operator.
   *
   * @see {@link BaseAccount#deactivate}.
   */
  public async detach(account: BaseAccount): Promise<void> {
    if (account.operator !== this) {
      throw new Error("Account uses another operator");
    }
    const address = account.address.toString();
    this._accounts.delete(address);
    await account.deactivate(false);
  }

  /**
   * Returns attached account by its address.
   *
   * @see {@link attach}, {@link detach}.
   */
  public getAccount<T extends BaseAccount>(
    address: PublicKey | string
  ): T | undefined {
    if (typeof address != "string") address = address.toString();
    return this._accounts.get(address) as T;
  }
}

/**
 * @group Operator
 * @internal
 */
export interface TransactionBlock {
  /**
   * Addresses of new accounts allocated by the transaction.
   */
  allocate?: PublicKey[];

  /**
   * Additional transaction signers of the block.
   */
  signers?: Keypair[];

  /**
   * Raw transaction instructions.
   */
  instructions: TransactionInstruction[];
}

/**
 * @group Operator
 */
export interface TransactionResult {
  /**
   * Transaction signature.
   */
  signature: string;

  /**
   * Transaction fee in ${@link SOL | SOLs}.
   */
  fee: Amount;

  /**
   * New accounts allocated within transaction mapped by their address.
   */
  accounts: Map<string, AccountInfo<Buffer>>;

  /**
   * Total rent excempt amount in {@link SOL | SOLs} of allocated accounts.
   */
  rent: Amount;
}
