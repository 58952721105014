import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { Button, Layout } from "../components"
import styles from "./GoldTokenRedeemed.module.scss"

const GoldTokenRedeemed = () => {
  const [showVideo, setShowVideo] = useState(true)
  useEffect(() => {
    if (window.innerWidth > 1250) {
      document.querySelector("video").setAttribute("width", 1250)
    }
    document.querySelector("video").addEventListener("ended", () => {
      setShowVideo(false)
    })
  }, [])

  let color = "gold"
  if (window.location.href.indexOf("crystal") > -1) {
    color = "crystal"
  }

  //crystaltilevideo.mp4

  return (
    <Layout className={styles.blackBackground}>
      <div
        className={classNames(
          styles.videoContainer,
          !showVideo && styles.hideVideo
        )}
      >
        <video
          autoPlay="autoplay"
          preload="auto"
          playsinline={true}
          playsInline={true}
          muted={true}
        >
          <source
            src={
              color === "gold"
                ? "https://hngr-icons.s3.amazonaws.com/tensor_nft_images/XP+Tile+Gold+Coin+Slot+Color+Corrected+Fadeout.mp4"
                : "https://hngr-icons.s3.amazonaws.com/tensor_nft_images/crystaltilevideo.mp4"
            }
            type="video/mp4"
          />
        </video>
      </div>
      <div className={styles.contentContainer}>
        <div
          className={classNames(
            styles.imageContainer,
            showVideo && styles.hideVideo
          )}
        >
          <img
            src={
              color == "gold"
                ? "https://hngr-icons.s3.amazonaws.com/tensor_nft_images/XP+Tile+Gold+2.gif"
                : "https://cdn.hngr.co/tensor_nft_images/xpcrystal.gif"
            }
            alt="Tile"
            className={styles.image}
          />
        </div>
        <div
          className={classNames(styles.content, showVideo && styles.hideVideo)}
        >
          <h1 className={styles.green}>It's Yours</h1>
          <div className={styles.rewardContainer}>
            {color === "gold" ? <h1>$200</h1> : <h1>$40</h1>}
            <p>in XP CASH</p>
          </div>
          <p className={styles.rewardDescription}>
            Use your XP cash to get tickets for your favorite live events.
          </p>
          <Link to="/events">
            <Button fullWidth variant="beigeLight">
              Discover Events Near Me
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export default GoldTokenRedeemed
