import { Button, Modal } from "../components"
import { CloseCircle } from "../css/icons"
import styles from "./UnsealModal.module.scss"

const UnsealModal = ({ close, unseal }) => (
  <Modal onClose={() => close()} width="medium">
    <div className={styles.unsealModalContainer}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <div className={styles.warningIcon}>⚠️</div>
      <div className={styles.unsealWarning}>
        Do you want to activate this ticket?
      </div>

      <div className={styles.subtext}>
        Your ticket can only be activated once. The public will know that this
        ticket has been opened on the blockchain.
      </div>
      <div className={styles.subtext}></div>

      <div className={styles.warningListContainer}>
        <div className={styles.warningListItem}>Please make sure:</div>
        <div className={styles.warningListItem}>
          You plan to attend this event
        </div>
        <div className={styles.warningListItem}>
          You do not want to sell this ticket
        </div>
        <div className={styles.warningListItem}>
          You’re ready to have a great time
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button variant="gray" onClick={() => close()}>
          Not Now
        </Button>
        <Button variant="blue" onClick={() => unseal()}>
          Unseal
        </Button>
      </div>
    </div>
  </Modal>
)

export default UnsealModal
