import classNames from "classnames"
import styles from "./Tile.module.scss"

const Tile = ({
  className,
  image,
  numberOfShows,
  onClick,
  subtitle,
  title,
}) => (
  <div className={classNames(styles.tile, className)} onClick={() => onClick()}>
    <img
      className={styles.arrow}
      alt="arrow"
      src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/arrow_noglow+1.png"
    />

    <div className={styles.tileLabel}>
      <span className={styles.number}>{numberOfShows} Shows</span>

      <span className={styles.subtitle}>{title}</span>
    </div>
  </div>
)

export default Tile
