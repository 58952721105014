import { useState } from "react"
import classNames from "classnames"
import { Button, Input, Modal } from "../components"
import styles from "./DecryptedModal.module.scss"
import { CloseCircle } from "../css/icons"

const DecryptedModal = ({ secret, close, onSubmit }) => {
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  return (
    <Modal onClose={() => close()}>
      <div className={styles.modalContainer}>
        <CloseCircle className={styles.close} onClick={() => close()} />

        <div className={styles.icon}>📲</div>
        <div className={styles.warning}>Claim my ticket</div>

        <div className={styles.subtext}>
          Your information remains private as we fetch the tickets from the
          external party on your behalf.
        </div>

        <div className={styles.listContainer}>
          <div className={styles.listItem}>
            You will be emailed a link to your ticket from secure.tickets
          </div>
          <div className={styles.listItem}>
            This link will grant you access to view event details and receive
            any updates related to the event.
          </div>
        </div>

        <div className={styles.inputLabel}>Email Address</div>
        <Input
          onChange={(e) => {
            setEmail(e.target.value)
            validateEmail(e.target.value)
          }}
          className={classNames(styles.input, validEmail && styles.validEmail)}
          placeholder="Email"
        />
        <Button
          variant="blue"
          fullWidth
          onClick={() =>
            // todo: send submit, but have this function actually live in the parent
            onSubmit(secret, email)
          }
          disabled={!validEmail}
        >
          Claim My Ticket
        </Button>
      </div>
    </Modal>
  )
}

export default DecryptedModal
