import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { Button, DateTag, EventCard, Layout, Modal } from "../components"
import styles from "./ArtistPage.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { formatLocationName } from "../helpers/location"
import {
  getEventsByPerformer,
  getFilteredEvents,
  getPerfomerById,
  resetEventsByPerformer,
  setSelectedEvent,
} from "../reducers/eventSlice"
import { AppleMusic, CloseCircle, Spotify, USDC, Venue } from "../css/icons"

const EventListItem = ({ event }) => {
  const dispatch = useDispatch()
  return (
    <Link
      className={styles.eventListItem}
      to={`/event/${event.event_id}`}
      onClick={() => dispatch(setSelectedEvent(event))}
    >
      <DateTag event={event} />
      <div className={styles.eventInfo}>
        <div>
          <h3>
            {event.venue_city}, {event.venue_state}
          </h3>
          <div className={styles.venueInfo}>
            <Venue />
            <p className={styles.venue}>{event.venue_name}</p>
          </div>
        </div>
        <p className={styles.priceDescription}>
          From <USDC />
          <span>${event.min_ticket_price} USDC</span>
        </p>
      </div>
    </Link>
  )
}

const ArtistPage = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const eventsByPerformer = useSelector(
    (state) => state.events.eventsByPerformer
  )
  const selectedPerformer = useSelector(
    (state) => state.events.selectedPerformer
  )
  const preferredLocation = useSelector((state) => state.user.preferredLocation)
  const unfilteredRecommendations = useSelector(
    (state) => state.events.filteredEventList
  )

  const [eventsInYourLocation, setEventsInYourLocation] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [filteredRecommendations, setFilteredRecommendations] = useState([])
  const [numberOfResults, setNumberOfResults] = useState(4)

  useEffect(() => {
    dispatch(getEventsByPerformer(params.artist_id))
    dispatch(getPerfomerById(params.artist_id))

    // if there is no location, this will return all events
    dispatch(getFilteredEvents({ cbsa: preferredLocation }))

    return () => {
      dispatch(resetEventsByPerformer())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getEventsByPerformer(params.artist_id))
    dispatch(getPerfomerById(params.artist_id))
  }, [params.artist_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterEvents(eventsByPerformer)
  }, [eventsByPerformer]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterRecommendations(unfilteredRecommendations)
  }, [unfilteredRecommendations]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterEvents = () => {
    let nearbyEvents = []
    let otherEvents = []
    eventsByPerformer?.forEach((event) => {
      if (event.venue_cbsa?.includes(preferredLocation)) {
        nearbyEvents.push(event)
      } else {
        otherEvents.push(event)
      }
    })

    setEventsInYourLocation(nearbyEvents)
    setFilteredEvents(otherEvents)
  }

  const filterRecommendations = () => {
    let recommendations = unfilteredRecommendations?.filter(
      (event) => event.performer_id !== parseInt(params.artist_id)
    )

    setFilteredRecommendations(recommendations)
  }

  // todo: modal clickaway listener

  return (
    <>
      <Layout>
        <div className={styles.artistPageContainer}>
          <div className={styles.artistDescriptionContainer}>
            <div
              className={styles.centerCropped}
              style={{
                backgroundImage: `url(${selectedPerformer?.default_img})`,
              }}
              alt={selectedPerformer?.name}
            />
            <div className={styles.artistInfoContainer}>
              <h1>{selectedPerformer?.name}</h1>

              <div className={styles.linkContainer}>
                {selectedPerformer?.spotify_id && (
                  <a
                    href={`https://open.spotify.com/artist/${selectedPerformer?.spotify_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="gray" leftIcon={<Spotify />}></Button>
                  </a>
                )}
                {selectedPerformer?.apple_id && (
                  <a
                    href={`https://music.apple.com/us/artist/${selectedPerformer?.apple_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="gray" leftIcon={<AppleMusic />}></Button>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={styles.artistEventList}>
            {preferredLocation && eventsInYourLocation?.length > 0 && (
              <div>
                <h1>
                  Near <span>{formatLocationName(preferredLocation)}</span>
                </h1>
                {eventsInYourLocation?.map((event) => (
                  <EventListItem event={event} />
                ))}
              </div>
            )}
            {filteredEvents?.length > 0 && (
              <div>
                <h2>All Events</h2>
                {filteredEvents?.map((event, i) => {
                  if (numberOfResults && i >= numberOfResults) return null
                  return <EventListItem event={event} />
                })}
              </div>
            )}

            {numberOfResults && filteredEvents?.length > numberOfResults && (
              <Button
                compact
                className={styles.showMoreButton}
                onClick={() => setNumberOfResults(null)}
                variant="gray"
              >
                Show All
              </Button>
            )}
          </div>
        </div>
        <div className={styles.eventRecommendations}>
          <h2>You may also like:</h2>
          <div className={styles.recommendationsContainer}>
            {filteredRecommendations?.length > 0 ? (
              <>
                {filteredRecommendations?.map((event, i) => {
                  if (i > 2) return null
                  return (
                    <EventCard
                      event={event}
                      key={event.event_id}
                      className={styles.card3w}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <div className={classNames(styles.loader, styles.card3w)} />
                <div className={classNames(styles.loader, styles.card3w)} />
                <div className={classNames(styles.loader, styles.card3w)} />
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ArtistPage
