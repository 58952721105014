export const Tickets = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 3.33333C2.66667 2.59792 3.33102 2 4.14815 2H14.5185C15.3356 2 16 2.59792 16 3.33333V4.66667C16 4.85 15.8287 4.99375 15.6366 5.05417C15.2014 5.18958 14.8889 5.5625 14.8889 6C14.8889 6.4375 15.2014 6.81042 15.6366 6.94583C15.8287 7.00625 16 7.15 16 7.33333V8.66667C16 9.40208 15.3356 10 14.5185 10H12.2222C12.2222 10.4375 12.5347 10.8104 12.9699 10.9458C13.162 11.0063 13.3333 11.15 13.3333 11.3333V12.6667C13.3333 13.4021 12.669 14 11.8519 14H1.48148C0.664352 14 0 13.4021 0 12.6667V11.3333C0 11.15 0.171296 11.0063 0.363426 10.9458C0.798611 10.8104 1.11111 10.4375 1.11111 10C1.11111 9.5625 0.798611 9.18958 0.363426 9.05417C0.171296 8.99375 0 8.85 0 8.66667V7.33333C0 6.59792 0.664352 6 1.48148 6H3.77778C3.77778 5.5625 3.46528 5.18958 3.03009 5.05417C2.83796 4.99375 2.66667 4.85 2.66667 4.66667V3.33333ZM13.3333 8.16255C13.5813 8.17168 13.7778 8.13599 13.7778 8V4C13.7778 3.63125 13.4468 3.33333 13.037 3.33333H5.62963C5.21991 3.33333 4.88889 3.63125 4.88889 4V6H5.62963V4.33333C5.62963 4.15 5.7963 4 6 4H12.6667C12.8704 4 13.037 4.15 13.037 4.33333V6.534C13.223 6.75685 13.3333 7.03368 13.3333 7.33333V8.16255ZM10.3704 7.33333H4.14815H2.96296C2.55324 7.33333 2.22222 7.63125 2.22222 8V12C2.22222 12.3687 2.55324 12.6667 2.96296 12.6667H10.3704C10.7801 12.6667 11.1111 12.3687 11.1111 12V8C11.1111 7.63125 10.7801 7.33333 10.3704 7.33333ZM3.33333 12C3.12963 12 2.96296 11.85 2.96296 11.6667V8.33333C2.96296 8.15 3.12963 8 3.33333 8H10C10.2037 8 10.3704 8.15 10.3704 8.33333V11.6667C10.3704 11.85 10.2037 12 10 12H3.33333Z"
      fill="#19B2E6"
    />
  </svg>
)
