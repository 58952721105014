export const Trunk = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 14.0892C0 14.592 0.447812 14.9999 1 14.9999H3V9.53559H0V14.0892ZM0 4.98202V8.62488H3V2.24988C1.34312 2.24988 0 3.47308 0 4.98202ZM11 10.9017C11 11.1532 10.7762 11.357 10.5 11.357H7.5C7.22375 11.357 7 11.1532 7 10.9017V9.53559H4V14.9999H14V9.53559H11V10.9017ZM15 14.9999H17C17.5522 14.9999 18 14.592 18 14.0892V9.53559H15V14.9999ZM4 8.62488H7V7.25881C7 7.00722 7.22375 6.80345 7.5 6.80345H10.5C10.7762 6.80345 11 7.00722 11 7.25881V8.62488H14V2.24988H4V8.62488ZM15 2.24988V8.62488H18V4.98202C18 3.47308 16.6569 2.24988 15 2.24988ZM9.5 9.99095V8.16952C9.5 7.91794 9.27625 7.71416 9 7.71416C8.72375 7.71416 8.5 7.91794 8.5 8.16952V9.99095C8.5 10.2425 8.72375 10.4463 9 10.4463C9.27625 10.4463 9.5 10.2425 9.5 9.99095Z"
      fill="#FAFAFA"
    />
  </svg>
)
