import { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Input } from "../components"
import classNames from "classnames"
import styles from "./SignInModal.module.scss"
import { AngleLeft, CloseCircle, X } from "../css/icons"
import { setUser } from "../reducers/userSlice"
import postToSlack from "../postToSlack"

const Api = require("../api")

const Verification = ({
  onClose,
  authUUID,
  phoneNumber,
  setCodeSent,
  validateAndSendText,
}) => {
  const dispatch = useDispatch()
  const initialOtpState = { otp1: "", otp2: "", otp3: "", otp4: "" }
  const [otp, setOtp] = useState(initialOtpState)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const inputfocus = (elmnt) => {
    if (
      !elmnt.target.value &&
      (elmnt.key === "Delete" || elmnt.key === "Backspace")
    ) {
      const next = elmnt.target.tabIndex - 2
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    } else {
      const next = elmnt.target.tabIndex
      if (next < 4) {
        elmnt.target.form.elements[next].focus()
      }
    }
  }

  const handleSubmit = () => {
    let value = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4
    console.log("otp", otp)
    console.log("value", value)
    console.log("auth uuid", authUUID)
    // copied from supperclub, assuming we'll want something like this here
    // the other piece that needs to change is we don't want to automatically redirect
    // on a successful code, we want the validation to happen on clicking continue
    Api.callApi({
      endpoint: "/supperclub/check-login-code",
      method: "post",
      data: {
        code: value,
        auth_uuid: authUUID,
        ticketdex:true
      },
      success: (resp) => {
        if (resp.success) {
          let message = `${resp.phone} logged in!`
          postToSlack(message, "xp")
          // use this set returned data to state wherever it needs to go
          let u = {
            phone:resp.phone,
            publicKey: resp.public_key,
            uuid: resp.diner_uuid,
            loginMethod: "phone",
          }
          localStorage.setItem("user", JSON.stringify(u))
          dispatch(setUser(u))
          onClose()
        } else {
          let message = `${resp.phone} had wrong login code`
          postToSlack(message, "xp")
          alert("Invalid code, please try again")
        }
      },
      failure: () => {
        alert("error")
      },
    })
  }

  const handleChange = (e) => {
    if (e.target.value.length === 4) {
      let otp = {
        otp1: e.target.value[0],
        otp2: e.target.value[1],
        otp3: e.target.value[2],
        otp4: e.target.value[3],
      }
      setOtp(otp)
      //handleSubmit(e, e.target.value)
    } else {
      setOtp({ ...otp, [e.target.name]: e.target.value })
      //if (e.target.name === "otp4") {
      //  handleSubmit(e)
      //}
    }
  }
  return (
    <div className={styles.signInModal}>
      <div className={styles.buttonRow}>
        <CloseCircle onClick={() => setCodeSent(false)}>
          <AngleLeft />
        </CloseCircle>
        <CloseCircle onClick={() => onClose()}>
          <X />
        </CloseCircle>
      </div>
      <h1>Enter your code</h1>
      <p>We sent you a code at {phoneNumber}</p>
      <form className={styles.verificationInputContainer}>
        <Input
          className={classNames(
            styles.verificationInput,
            error && styles.fieldError,
            success && styles.fieldSuccess
          )}
          placeholder="0"
          maxLength="1"
          type="numeric"
          name="otp1"
          value={otp.otp1}
          onChange={(e) => {
            handleChange(e)
          }}
          onKeyUp={(event) => inputfocus(event)}
          tabIndex="1"
        />
        <Input
          className={classNames(
            styles.verificationInput,
            error && styles.fieldError,
            success && styles.fieldSuccess
          )}
          placeholder="0"
          maxLength="1"
          type="numeric"
          name="otp2"
          onChange={(e) => {
            handleChange(e)
          }}
          onKeyUp={(event) => inputfocus(event)}
          tabIndex="2"
          value={otp.otp2}
        />
        <Input
          className={classNames(
            styles.verificationInput,
            error && styles.fieldError,
            success && styles.fieldSuccess
          )}
          placeholder="0"
          maxLength="1"
          type="numeric"
          name="otp3"
          onChange={(e) => {
            handleChange(e)
          }}
          onKeyUp={(event) => inputfocus(event)}
          tabIndex="3"
          value={otp.otp3}
        />
        <Input
          className={classNames(
            styles.verificationInput,
            error && styles.fieldError,
            success && styles.fieldSuccess
          )}
          placeholder="0"
          maxLength="1"
          type="numeric"
          name="otp4"
          onChange={(e) => {
            handleChange(e)
          }}
          onKeyUp={(event) => inputfocus(event)}
          onKeyDown={(e) => handleChange(e)}
          tabIndex="4"
          value={otp.otp4}
        />
      </form>

      <div className={styles.modalLink} onClick={() => validateAndSendText()}>
        Resend Code
      </div>
      <Button
        variant="blue"
        fullWidth
        className={styles.verificationButton}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </div>
  )
}

export default Verification
