export const Circle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00003 1.33276C11.6827 1.33276 14.668 4.3181 14.668 8.00076C14.668 11.6828 11.6827 14.6681 8.00003 14.6681C4.31736 14.6681 1.33203 11.6828 1.33203 8.00076C1.33203 4.3181 4.31736 1.33276 8.00003 1.33276ZM8.00003 2.33276C7.25057 2.32464 6.50694 2.46523 5.81218 2.74641C5.11741 3.02759 4.48532 3.44378 3.95247 3.97087C3.41963 4.49797 2.99661 5.12552 2.70791 5.81719C2.41921 6.50886 2.27056 7.25093 2.27056 8.00043C2.27056 8.74994 2.41921 9.492 2.70791 10.1837C2.99661 10.8753 3.41963 11.5029 3.95247 12.03C4.48532 12.5571 5.11741 12.9733 5.81218 13.2544C6.50694 13.5356 7.25057 13.6762 8.00003 13.6681C9.48805 13.6453 10.9074 13.0381 11.9516 11.9778C12.9958 10.9174 13.5811 9.48895 13.5811 8.00076C13.5811 6.51257 12.9958 5.08408 11.9516 4.02374C10.9074 2.96339 9.48805 2.35559 8.00003 2.33276Z"
      fill="#8D8D8D"
    />
  </svg>
)
