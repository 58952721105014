export const XCircle = ({ className, onClick }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => onClick && onClick()}
    className={className}
  >
    <path
      d="M7.99992 1.83334C4.31712 1.83334 1.33325 4.81721 1.33325 8.5C1.33325 12.1828 4.31712 15.1667 7.99992 15.1667C11.6827 15.1667 14.6666 12.1828 14.6666 8.5C14.6666 4.81721 11.6827 1.83334 7.99992 1.83334ZM11.2687 10.25C11.3951 10.3763 11.3951 10.5806 11.2687 10.707L10.2042 11.7688C10.0779 11.8952 9.87357 11.8952 9.74723 11.7688L7.99992 10.0054L6.24992 11.7688C6.12357 11.8952 5.91927 11.8952 5.79293 11.7688L4.7311 10.7043C4.60476 10.578 4.60476 10.3737 4.7311 10.2473L6.49454 8.5L4.7311 6.75C4.60476 6.62366 4.60476 6.41936 4.7311 6.29301L5.79562 5.2285C5.92196 5.10215 6.12626 5.10215 6.25261 5.2285L7.99992 6.99463L9.74992 5.23119C9.87626 5.10484 10.0806 5.10484 10.2069 5.23119L11.2714 6.2957C11.3978 6.42205 11.3978 6.62635 11.2714 6.75269L9.5053 8.5L11.2687 10.25Z"
      fill="#FAFAFA"
      fill-opacity="0.5"
    />
  </svg>
)
