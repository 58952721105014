export const Play = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9686 7.42334L4.80233 2.00429C4.05757 1.5642 2.91699 1.99127 2.91699 3.07976V13.9153C2.91699 14.8918 3.97685 15.4803 4.80233 14.9907L13.9686 9.57429C14.7863 9.09254 14.7889 7.90509 13.9686 7.42334Z"
      fill="#181A1E"
    />
  </svg>
)
