import { Button, Modal } from "../components"
import { CloseCircle } from "../css/icons"
import styles from "./ErrorModal.module.scss"

const ErrorModal = ({ close, unseal }) => (
  <Modal onClose={() => close()} width="medium">
    <div className={styles.container}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <div className={styles.icon}>🚫</div>
      <h1 className={styles.header}>Oops, something went wrong</h1>

      <div className={styles.infoContainer}>
        <div className={styles.listItem}>
          We're sorry, but an error occurred while processing your request.
          Please try again later.
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="gray" onClick={() => close()}>
          Close
        </Button>
        <Button variant="blue" onClick={() => unseal()}>
          Try again
        </Button>
      </div>
    </div>
  </Modal>
)

export default ErrorModal
