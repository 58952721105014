import classNames from "classnames"
import styles from "./CloseCircle.module.scss"
import { X } from "./X"

export const CloseCircle = ({ children, className, onClick }) => (
  <div
    className={classNames(styles.closeCircle, className)}
    onClick={() => onClick && onClick()}
  >
    {children || <X />}
  </div>
)
