import { CloseCircle } from "../css/icons"
import { Button, Modal } from "../components"
import styles from "./AccessRequestedModal.module.scss"

const AccessRequestedModal = ({ close }) => {
  return (
    <Modal onClose={() => close()} className={styles.accessModal}>
      <div className={styles.container}>
        <CloseCircle className={styles.close} onClick={() => close()} />
        <div className={styles.icon}>✅</div>
        <div className={styles.header}>Access Requested</div>
        <div className={styles.subtext}>
          Thank you for requesting early access
        </div>
        <div className={styles.infoContainer}>
          You're now on the list for early access to our private beta. Keep an
          eye on your inbox for updates and instructions on how to get started.
          Thank you for joining us on this exciting journey!
        </div>
        <Button fullWidth variant="blue" onClick={() => close()}>
          Continue
        </Button>
      </div>
    </Modal>
  )
}

export default AccessRequestedModal
